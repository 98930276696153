import React, { useState, useEffect } from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { Autocomplete, RenderInputParams } from '@material-ui/lab';
import { TextField, CircularProgress } from '@material-ui/core';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import { IAutoCompleteItem } from '../../typings/autoComplete';
import gql from 'graphql-tag';
import { useQuery } from 'react-apollo';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
        minWidth: '200px',
    },
  })
);

export interface FinancialYearItem extends IAutoCompleteItem, FinancialYear {}

interface FinancialYearAutoCompleteProps {
    disabled?: boolean;
    required?: boolean;
    multiple?: boolean;
    // tslint:disable-next-line: no-any
    value?: FinancialYearItem | any; // value only available in single select
    error?: boolean;
    onSelection?: (value: FinancialYearItem | FinancialYearItem[], name: string) => void;
    className?: string;
    helperText?: string;
    name?: string;
    label?: string;
    disablePortal?: boolean;
}

interface FinancialYearAutoCompleteState {
    isLoading: boolean;
    // tslint:disable-next-line: no-any
    options: FinancialYearItem[];
    // tslint:disable-next-line: no-any
    value: FinancialYearItem | any;
}

export const FinancialYearAutoComplete: React.FC<FinancialYearAutoCompleteProps> = props => {

    const classes = useStyles();

    const [state, setState] = useState<FinancialYearAutoCompleteState>({
        isLoading: true,
        options: [],
        value: props.value
    });

    const budgetFinancialYearQuery = useQuery<FinancialYearData>(BUDGET_FINANCIAL_YEAR);

    useEffect(() => {
        if (budgetFinancialYearQuery.data && !budgetFinancialYearQuery.loading && !budgetFinancialYearQuery.error) {

            const financialYear = budgetFinancialYearQuery.data ? budgetFinancialYearQuery.data.staffBudget.financialYearList.financialYear : [];
            setState((prevState) => {
                return {
                    ...prevState,
                    options: financialYear.map((source: FinancialYear) => ({...source, value: source.description, label: source.description })),
                    // tslint:disable-next-line: no-any
                    // value: financialYear.map((source: FinancialYear) => ({...source, value: source.description, label: source.description })).find((v: any) => v.isCurrent),
                    isLoading: false
                };
            });
        }
    // tslint:disable-next-line: align
    }, [budgetFinancialYearQuery.data, budgetFinancialYearQuery.loading, budgetFinancialYearQuery.error]);

    useEffect(() => {
        setState((prevState) => {

            let tempValue = props.value;

            if (!tempValue) {
                // tslint:disable-next-line: no-any
                tempValue = state.options.find((v: any) => v.isCurrent);
                if (props.onSelection) {
                    props.onSelection(tempValue, props.name ? props.name : 'financialYear');
                }
            }

            return {
                ...prevState,
                // tslint:disable-next-line: no-any
                value: tempValue
            };
        });
    // tslint:disable-next-line: align
    }, [props.value, state.options]);

    // tslint:disable-next-line: no-any
    const handleChange = (event: React.ChangeEvent<{}>, value: any) => {
        if (props.onSelection) {
            props.onSelection(value, props.name ? props.name : 'financialYear');
        }
    };

    const getInputProps = (params: RenderInputParams, isLoading?: boolean) => {
        return  ({   
            ...params.InputProps,
            endAdornment: (
                <React.Fragment>
                  {isLoading ? <CircularProgress color="primary" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),     
         });
    };

    return (
        <div className={`${classes.root} ${props.className}`}>
            { props.multiple ? 
                (
                    <Autocomplete
                        value={state.value}
                        multiple={true}
                        loading={state.isLoading}
                        disabled={props.disabled}
                        onChange={handleChange}
                        options={state.options}
                        disablePortal={props.disablePortal} // to Stop the dropdown select from closing the form
                        // tslint:disable-next-line: jsx-no-lambda
                        getOptionLabel={(option: IAutoCompleteItem) => option.label}  
                        getOptionSelected={(option, value) => value.value === option.value}                  
                        // tslint:disable-next-line: jsx-no-lambda
                        renderInput={params => (
                            <TextField 
                                {...params} 
                                label={props.label ? props.label : 'Financial Year'}
                                variant="standard" 
                                fullWidth={true} 
                                margin="none" 
                                required={props.required}
                                InputProps={getInputProps(params, state.isLoading)}
                                error={props.error}      
                                helperText={props.helperText}                      
                            />
                        )}
                        // tslint:disable-next-line: jsx-no-lambda
                        renderOption={(option, { inputValue }) => {
                            const matches = match(option.label, inputValue);
                            const parts = parse(option.label, matches);
                    
                            return (
                                <div>
                                    {parts.map((part, index) => (
                                        <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                                            {part.text}
                                        </span>
                                    ))}
                                </div>
                            );
                        }}
                    />
                )
                :
                (
                    <Autocomplete
                        value={state.value}
                        loading={state.isLoading}
                        disabled={props.disabled}
                        onChange={handleChange}
                        options={state.options}
                        disablePortal={props.disablePortal} // to Stop the dropdown select from closing the form
                        // tslint:disable-next-line: jsx-no-lambda
                        getOptionLabel={(option: IAutoCompleteItem) => option.label}     
                        getOptionSelected={(option, value) => value.value === option.value}    
                        // tslint:disable-next-line: jsx-no-lambda
                        renderInput={params => (
                            <TextField 
                                {...params} 
                                label={props.label ? props.label : 'Financial Year'}
                                variant="standard" 
                                fullWidth={true} 
                                margin="none" 
                                required={props.required}
                                InputProps={getInputProps(params, state.isLoading)}
                                error={props.error}       
                                helperText={props.helperText}                          
                            />
                        )}
                        // tslint:disable-next-line: jsx-no-lambda
                        renderOption={(option, { inputValue }) => {
                            const matches = match(option.label, inputValue);
                            const parts = parse(option.label, matches);
                    
                            return (
                                <div>
                                {parts.map((part, index) => (
                                    <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                                    {part.text}
                                    </span>
                                ))}
                                </div>
                            );
                        }}
                    />
                )
            }
        </div>
    );
};

export interface FinancialYearData {
    staffBudget: StaffBudget;
}

export interface StaffBudget {
    financialYearList: FinancialYearList;
}

export interface FinancialYearList {
    recordCount:   number;
    financialYear: FinancialYear[];
}

export interface FinancialYear {
    id:          number;
    startDate:   Date;
    endDate:     Date;
    description: string;
    isCurrent: boolean;
}

const BUDGET_FINANCIAL_YEAR = gql`
    query budgetFinancialYear {
        staffBudget {
            financialYearList {      
                recordCount,
                financialYear{
                    id,
                    startDate,
                    endDate,
                    description,
                    isCurrent
                }
            }
        }
    }
`;