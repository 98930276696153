import gql from 'graphql-tag';
import { FetchPolicy } from 'apollo-client';
import { client } from '..';

export interface StaffSummaryQueryParams {
    offset: number; 
    first: number;
    filter?: string;   
    includeInactive: boolean;
}

export interface StaffSummaryData {
    loading?: boolean;
    networkStatus?: number;
    party: Party;
}

export interface Party {
    staffSummaries: StaffSummaries;
}

export interface StaffSummaries {
    recordCount: number;
    staffSummaryActualCount: StaffSummaryActualCount;
    staffSummary: StaffSummary[];
}

export interface StaffSummaryActualCount {
    actualRowCountForSearch:   number;
}

export interface StaffSummary {
    staffId: number;
    staffGuid: string;
    name: string | null;
    emailAddress: string | null;
    mobile: string | null;
    phone: string | null;
    staffRole: string | null;
    startDate: Date | null;
    endDate: Date | null;
    office: string | null;
    department: string | null;
    team: string | null;
}

export function retrieveStaffSummaryData(query: StaffSummaryQueryParams, 
                                         refreshData: boolean,
                                         onSuccess: (data: StaffSummaryData) => void,
                                        // tslint:disable-next-line:no-any
                                         onError: (reason: any) => void)
                                        : void {
    var fetchPolicy: FetchPolicy = refreshData === true ? 'network-only' : 'cache-first';
    client.query({
        query: NameData,
        variables: {
            offset: query.offset,
            first: query.first,
            filter: query.filter,    
            includeInactive: query.includeInactive        
        },
        fetchPolicy: fetchPolicy
    })
    // tslint:disable-next-line:no-any
    .then((results: { data: any; }) => {
            onSuccess(results.data);
            })
    // tslint:disable-next-line:no-any
    .catch((reason: any ) => { onError(reason); }
    );
}

const NameData = gql`
    query StaffSummaries($offset: Int, $first: Int, $filter: String, $includeInactive: Boolean) {
        party{
            staffSummaries(offset: $offset, first: $first, filter: $filter, includeInactive: $includeInactive){
            recordCount,
            staffSummaryActualCount{
                actualRowCountForSearch
            },
            staffSummary{
                    staffId,
                    staffGuid
                    name,
                    emailAddress,
                    mobile,
                    phone,
                    staffRole
                    startDate
                    endDate,
                    isLicensed,
                    office,
                    department,
                    team
                }
            }
        }
    }
`;

export interface StaffDailyBudgetsParams {
    staffGuid: string | null;
    startDate: Date | null;
    endDate: Date | null;
}
export interface StaffDailyBudgetsData {
    loading?: boolean;
    networkStatus?: number;
    staffBudget: StaffBudget;
}

export interface StaffBudget {
    staffDailyBudgets: StaffDailyBudgets;
}

export interface StaffDailyBudgets {
    recordCount:      number;
    staffDailyBudget: StaffDailyBudget[];
    staffFinancialYearBudget: StaffFinancialYearBudget;
}

export interface StaffDailyBudget {
    id:               number;
    staffGuid:        string;
    budgetDate:       Date;
    billableHours:    string;
    nonBillableHours: string;
    feesBilled:       number;
    feesCollected:    number;
    billableFees:     number;
}

export interface StaffFinancialYearBudget {
    id:                        number;
    staffGuid:                 string;
    billableHours:             string;
    nonBillableHours:          string;
    feesBilled:                number;
    feesCollected:             number;
    billableFees:              number;
    financialPeriodStartDate: Date;
    financialPeriodEndDate:   Date;
}

export function retrieveStaffDailyBudgetsData(query: StaffDailyBudgetsParams, 
                                              refreshData: boolean,
                                              onSuccess: (data: StaffDailyBudgetsData) => void,
   // tslint:disable-next-line:no-any
                                              onError: (reason: any) => void)
   : void {
    var fetchPolicy: FetchPolicy = refreshData === true ? 'network-only' : 'cache-first';
    client.query({
        query: StaffDailyBudgetsQuery,
        variables: {
            staffGuid: query.staffGuid,
            startDate: query.startDate,
            endDate: query.endDate
        },
    fetchPolicy: fetchPolicy
    })
    // tslint:disable-next-line:no-any
    .then((results: { data: any; }) => {
        onSuccess(results.data);
    })
    // tslint:disable-next-line:no-any
        .catch((reason: any ) => { onError(reason); }
    );
}

export const StaffDailyBudgetsQuery = gql`
    query staffDailyBudgets($staffGuid: String, $startDate: DateTime, $endDate: DateTime) {
        staffBudget{
            staffDailyBudgets(staffGuid: $staffGuid, startDate: $startDate, endDate: $endDate){
                recordCount,
                staffFinancialYearBudget{
                    id,
                    staffGuid,
                    financialPeriodStartDate,
                    financialPeriodEndDate,
                    billableHours,
                    nonBillableHours,
                    feesBilled,
                    feesCollected,
                    billableFees
                },
                staffDailyBudget{
                    id,
                    staffGuid,
                    budgetDate,
                    billableHours,
                    nonBillableHours,
                    feesBilled,
                    feesCollected,
                    billableFees
                }
            }
        }
    }
`;
export interface CurrencySymbolData {
    options: Options;
}

export interface Options {
    currencySymbol: CurrencySymbol;
}

export interface CurrencySymbol {
    symbol: string;
}

export const CurrencySymbol = gql`
    query CurrencySymbol {
        options {currencySymbol {symbol}}
    }
`;