/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import {
    Popper,
    Grow,
    Paper,
    ClickAwayListener,
    MenuList,
    MenuItem,
    IconButton
} from '@material-ui/core';
import { RvLoader } from '../components/Loader';
import MoreHorizOutlinedIcon from '@material-ui/icons/MoreHorizOutlined';
import { StaffBudgetMaintenanceModal } from './modals/StaffBudgetMaintenanceModal';
import DateRangeOutlinedIcon from '@material-ui/icons/DateRangeOutlined';
import { UserFeature, UserFeatureEnum } from '../types/UserFeature';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import { EditStaffBudgetDrawer } from './drawer/EditStaffBudgetDrawer';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            // position: 'fixed',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%',
        },
        loaderWrapper: {
            position: 'absolute',
            top: 0,
            bottom: 0,
            right: 0,
            left: 0,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        menuItem: {
            color: '#5173FF',
        },
        menuIcon: {
            marginRight: '10px',
            color: '#5173FF'
        },
    })
);

export enum StaffTaskActionEnum {
    Budget = 1,
    Edit
}

interface StaffTaskActionProps {
    // tslint:disable-next-line: no-any
    data?: any;
    staffGuid: string;
}

interface StaffTaskActionState {
    isBudgetFormOpen: boolean;
    isEditFormOpen: boolean;
}
// tslint:disable-next-line: no-any
export const StaffTaskAction: React.FC<StaffTaskActionProps> = ( props ) => {
    const classes = useStyles();
    const anchorRef = React.useRef<HTMLButtonElement>(null);
    
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [open, setOpen] = useState(false);

    const [state, setState] = useState<StaffTaskActionState>({
        isBudgetFormOpen: false,
        isEditFormOpen: false
    });
    const staffId: string | undefined = props.data && props.data.staffId ? props.data.staffId : undefined;

    const handleToggle = () => {
        // tslint:disable-next-line: no-shadowed-variable
        setOpen(prevOpen => !prevOpen);
        setIsLoading(false);
    };

    const handleClose = (event: React.MouseEvent<EventTarget>) => {
        if (anchorRef.current &&
            anchorRef.current.contains(event.target as HTMLElement)
        ) {
            return;
        }

        setOpen(false);
    };

    const archorEvent = (event: React.MouseEvent<EventTarget>) => {
        if (anchorRef.current &&
            anchorRef.current.contains(event.target as HTMLElement)) {
            return;
        }
    };

    const handleClickMenu = (menu: StaffTaskActionEnum) => (event: React.MouseEvent<EventTarget>) => {
        if (anchorRef.current &&
            anchorRef.current.contains(event.target as HTMLElement)
        ) {
            return;
        }

        switch (menu) {
            case StaffTaskActionEnum.Budget : {
                setOpen(false);
                onBudgetFormOpen(event);
                break;
            }   
            case StaffTaskActionEnum.Edit : {
                setOpen(false);
                onEditFormOpen(event);
                break;
            }           
            default: {
                setOpen(false);
                break;
            }
        }
    };

    const onBudgetFormOpen = (event: React.MouseEvent<EventTarget>) => {
        archorEvent(event);
        setOpen(false);

        setState({
            ...state,
            isBudgetFormOpen: true
        });
    };

    const onEditFormOpen = (event: React.MouseEvent<EventTarget>) => {
        archorEvent(event);
        setOpen(false);

        setState({
            ...state,
            isEditFormOpen: true
        });
    };
    
    const onBudgetFormClosed = () => {

        setState({
            ...state,
            isBudgetFormOpen: false
        });
    };

    const onCloseEditForm = () => {

        setState({
            ...state,
            isEditFormOpen: false
        });
    };

    // const onDialogClose = () => {
    //     setOpen(false);

    //     setState({
    //         ...state,
    //         isBudgetFormOpen: false
    //     });
    // };

    function handleListKeyDown(event: React.KeyboardEvent) {
        if (event.key === 'Tab') {
            event.preventDefault();
            setOpen(false);
        }
    }

    // return focus to the button when we transitioned from !open -> open
    const prevOpen = React.useRef(open);
    React.useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current!.focus();
        }

        prevOpen.current = open;
        // tslint:disable-next-line: align
    }, [open]);

    const getTaskActions = () => {
        if (staffId) {
            return (
                <React.Fragment>
                    {state.isBudgetFormOpen && (
                        <StaffBudgetMaintenanceModal 
                            open={state.isBudgetFormOpen}
                            onClose={onBudgetFormClosed}
                            data={props.data}
                        />
                    )}
                    {state.isEditFormOpen && (
                        <EditStaffBudgetDrawer 
                            open={state.isEditFormOpen}
                            onClose={onCloseEditForm}
                            data={props.data}
                        />
                    )}
                </React.Fragment>
            );
        } else {
            return null;
        }
    };

    const getLoader = () => {
        return (
            <div className={classes.loaderWrapper}>
                <RvLoader />
            </div>
        );
    };

    const getMenuItems = () => {
        return (
            <React.Fragment>  
                <Popper
                    open={open}
                    anchorEl={anchorRef.current}
                    role={undefined}
                    transition={true}
                    disablePortal={false}
                    style={{
                        marginLeft: '100px'                                                       
                    }}   
                >
                    {({ TransitionProps, placement }) => (
                        <Grow
                            {...TransitionProps}
                            style={{
                                transformOrigin:
                                    placement === 'bottom'
                                        ? 'center top'
                                        : 'center bottom',
                            }}
                        >  
                            <React.Fragment>
                                <Paper>
                                    <ClickAwayListener onClickAway={handleClose}>
                                        <MenuList
                                            autoFocusItem={open}
                                            id="menu-list-grow"
                                            onKeyDown={handleListKeyDown}
                                        >
                                            {UserFeature.HasAccess(UserFeatureEnum.hasStaffMaintenance) && (
                                                <MenuItem
                                                    onClick={handleClickMenu(StaffTaskActionEnum.Edit)}
                                                    disabled={false}
                                                    className={classes.menuItem}
                                                >
                                                    <EditOutlinedIcon className={classes.menuIcon} />
                                                    Edit
                                                </MenuItem>
                                            )}
                                            <MenuItem
                                                onClick={handleClickMenu(StaffTaskActionEnum.Budget)}
                                                disabled={false}
                                                className={classes.menuItem}
                                            >
                                                <DateRangeOutlinedIcon className={classes.menuIcon} />
                                                Maintain Budgets
                                            </MenuItem>
                                        </MenuList>
                                    </ClickAwayListener>                                                    
                                </Paper>
                            </React.Fragment>                        
                        </Grow>
                    )}
                </Popper>
            </React.Fragment> 
        );
    };

    const hasBudgetPermission = () => {
        return  UserFeature.HasAccess(UserFeatureEnum.hasStaffBudgetCollectionsView) ||
                UserFeature.HasAccess(UserFeatureEnum.hasStaffBudgetCollectionsEdit) ||
                UserFeature.HasAccess(UserFeatureEnum.hasStaffBudgetBillingsView) ||
                UserFeature.HasAccess(UserFeatureEnum.hasStaffBudgetBillingsEdit) ||
                UserFeature.HasAccess(UserFeatureEnum.hasStaffBudgetProductionView) ||
                UserFeature.HasAccess(UserFeatureEnum.hasStaffBudgetProductionEdit);
    };

    return (
        <div className={classes.root}>
            {hasBudgetPermission() && (
                <IconButton
                    color="primary"
                    ref={anchorRef}
                    aria-controls={open ? 'menu-list-grow' : undefined}
                    aria-haspopup="true"
                    onClick={handleToggle}
                >
                    <MoreHorizOutlinedIcon />
                </IconButton>
            )}

            {
                isLoading
                ? getLoader()
                : getMenuItems()                  
            }

            {getTaskActions()} 
        </div>
    );
};
