import { DataType } from '../components/DataDisplay/ValueFormatter';
import { UserFeature, UserFeatureEnum } from '../types/UserFeature';
import { StaffTaskAction } from './StaffTaskAction';
var menuTabs = ['filterMenuTab', 'generalMenuTab', 'columnsMenuTab'];

export function HasBudgetEditPermission(): boolean {
    return UserFeature.HasAccess(UserFeatureEnum.hasStaffBudgetCollectionsEdit) ||
    UserFeature.HasAccess(UserFeatureEnum.hasStaffBudgetBillingsEdit) ||
    UserFeature.HasAccess(UserFeatureEnum.hasStaffBudgetProductionEdit);
}

export enum cellClassEnum {
    numberType = 'numberType',
    currencyType = 'currencyType',
    stringType = 'stringType',
    dateTimeType = 'dateTimeType',
    dateType = 'dateType',
    booleanType = 'booleanType',
    actionType = 'actionType',
    imageType = 'imageType',
    currencyWithoutDecimalsType = 'currencyWithoutDecimalsType',
    percentageType = 'percentageType',
    linkType = 'linkType',
    checkboxType = 'checkboxType',
    editorViewType = 'editorViewType',
    durationType = 'durationType',
    chipsType = 'chipsType',
    contextMenuStaffType = 'contextMenuStaffType',
    dueDateType = 'dueDateType',
    timeType = 'timeType',
    ratingType = 'ratingType',
    dateMonthYear = 'dateMonthYear'
}
                                
export const StaffSummaryColumns = [
    {
        field: 'action',
        format: DataType.ContextMenuStaff,
        headerName: '',        
        cellStyle: { overflow: 'visible' },
        width: 70,
        pinned: 'left',
        suppressMenu: true,
        autoHeight: true,
        resizable: false,
        lockPosition: true,
        lockPinned: true,
        cellRendererFramework: StaffTaskAction, // ActionCellRenderer
        cellClass: cellClassEnum.actionType
    },
    {
        headerName: 'Name', 
        field: 'name', 
        format: DataType.String,
        enableRowGroup: true, 
        suppressSizeToFit: true,
        width: 240,
        menuTabs: menuTabs,
        cellClass: cellClassEnum.stringType
    },
    {
        headerName: 'Email', 
        field: 'emailAddress', 
        format: DataType.String,
        enableRowGroup: true, 
        suppressSizeToFit: true,
        width: 240,
        menuTabs: menuTabs,
        cellClass: cellClassEnum.stringType
    },
    {
        headerName: 'Mobile', 
        field: 'mobile', 
        format: DataType.String,
        enableRowGroup: true, 
        suppressSizeToFit: true,
        width: 240,
        menuTabs: menuTabs,
        cellClass: cellClassEnum.stringType
    },
    {
        headerName: 'Phone', 
        field: 'phone', 
        format: DataType.String,
        enableRowGroup: true, 
        suppressSizeToFit: true,
        width: 240,
        menuTabs: menuTabs,
        cellClass: cellClassEnum.stringType
    },
    {
        headerName: 'Staff Classification', 
        field: 'staffRole', 
        format: DataType.String,
        enableRowGroup: true, 
        suppressSizeToFit: true,
        width: 240,
        menuTabs: menuTabs,
        cellClass: cellClassEnum.stringType,
        hide: false
    },
    {
        headerName: 'Start Date', 
        field: 'startDate', 
        format: DataType.Date,
        enableRowGroup: true, 
        suppressSizeToFit: true,
        width: 240,
        menuTabs: menuTabs,
        cellClass: cellClassEnum.dateType,
        hide: false
    },
    {
        headerName: 'End Date', 
        field: 'endDate', 
        format: DataType.Date,
        enableRowGroup: true, 
        suppressSizeToFit: true,
        width: 240,
        menuTabs: menuTabs,
        cellClass: cellClassEnum.dateType,
        hide: false
    },
    {
        headerName: 'PMS Access', 
        field: 'isLicensed', 
        format: DataType.Boolean,
        enableRowGroup: true, 
        suppressSizeToFit: true,
        width: 240,
        menuTabs: menuTabs,
        cellClass: cellClassEnum.booleanType,
        hide: false
    },
    {
        headerName: 'Office', 
        field: 'office', 
        format: DataType.String,
        enableRowGroup: true, 
        suppressSizeToFit: true,
        width: 240,
        menuTabs: menuTabs,
        cellClass: cellClassEnum.stringType,
        hide: false
    },
    {
        headerName: 'Department', 
        field: 'department', 
        format: DataType.String,
        enableRowGroup: true, 
        suppressSizeToFit: true,
        width: 240,
        menuTabs: menuTabs,
        cellClass: cellClassEnum.stringType,
        hide: false
    },
    {
        headerName: 'Team', 
        field: 'team', 
        format: DataType.String,
        enableRowGroup: true, 
        suppressSizeToFit: true,
        width: 240,
        menuTabs: menuTabs,
        cellClass: cellClassEnum.stringType,
        hide: false
    },
];

// .filter((value) => HasBudgetPermission() ? value : value.field !== 'action');