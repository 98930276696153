/* eslint-disable react-hooks/exhaustive-deps */
import FullCalendar, { CalendarApi, DatesSetArg } from '@fullcalendar/react';
import { createStyles, IconButton, makeStyles, Modal, Paper, Theme, Tooltip, withStyles } from '@material-ui/core';
import React, { useEffect, useRef, useState } from 'react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin, { DateClickArg } from '@fullcalendar/interaction'; // needed for dayClick
import { CurrencySymbol, CurrencySymbolData, retrieveStaffDailyBudgetsData, StaffDailyBudget,
     StaffDailyBudgetsData, StaffDailyBudgetsParams } from '../StaffSummaryRepository';
import { showNotification } from '../../App';
import { useQuery } from 'react-apollo';
import CancelIcon from '@material-ui/icons/Cancel';
import Moment from 'moment';
import { extendMoment } from 'moment-range';
import { RvLoader } from '../../components/Loader';
import { UserFeature, UserFeatureEnum } from '../../types/UserFeature';
import EditIcon from '@material-ui/icons/Edit';
// import DeleteIcon from '@material-ui/icons/Delete';
import { StaffBudgetForm, StaffBudgetMaintenanceDailog } from '../dialog/StaffBudgetMaintenanceDailog';
import { client } from '../..';
import gql from 'graphql-tag';
import { HasBudgetEditPermission } from '../StaffSummaryColumns';
import { formatNumber } from '../../helpers/ChartHelper';
import DateRangeIcon from '@material-ui/icons/DateRange';
import ViewWeekIcon from '@material-ui/icons/ViewWeek';
import EventIcon from '@material-ui/icons/Event';
import TodayIcon from '@material-ui/icons/Today';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { StaffNameBudgetDialog } from './StaffNameBudgetDialog';
import ReorderIcon from '@material-ui/icons/Reorder';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            maxWidth: '1400px',
            margin: '0 auto',
            position: 'absolute',
            top: '7%',
            left: '10px',
            bottom: '10px',
            right: '10px',
            display: 'flex',
            // width: '100%',
            // transform: 'translate(-50%, -50%)',
            padding: 15,
            flexFlow: 'column',
            flex: 1,
        },
        modalWrapper: {

        },
        calendarWrapper: {
            flex: 1,
            display: 'flex',
            position: 'relative',
        },
        closeButton: {
            position: 'absolute',
            top: '-111px',
            right: '-60px',
            zIndex: 999,
            backgroundColor: '#fff',
            '&:hover': {
                backgroundColor: '#000',
                color: '#fff'
            }
        },
        eventContentContainer: {
            padding: 1,
            display: 'flex',
            flexFlow: 'column',
            flex: 1,
            height: '100%',
            width: '100%',
            '& .event-content': {
                display: 'flex',
                flexFlow: 'row wrap',
                justifyContent: 'space-between',
                '& .label': {
                    marginRight: 5,
                }
            }
        },
        calendarContainer: {
            display: 'flex',
            flex: 1,
            '& .fc-daygrid-day': {
                height: 143.031,
                width: 151.562,
            },
            '& .fc-scroller-liquid-absolute': {
                overflow: 'auto !important',
            },
            '& > div.fc-theme-standard': {
                display: 'flex',
                flexFlow: 'column',
                flex: 1,
                '& > div.fc-view-harness': {
                    height: 'auto !important'
                }
            },
            '& .fc-button-primary': {
                backgroundColor: '#5173FF !important',
                borderColor: '#5173FF !important',
                boxShadow: 'none !important',
            },
            '& .fc-button-primary:hover': {
                backgroundColor: 'green !important',
                borderColor: 'green !important',
            },
            '& .fc-button-active': {
                backgroundColor: 'green !important',
                borderColor: 'green !important',
            },
            '& .fc-header-toolbar': {
                margin: '0 !important'
            }
        },
        weeklyTotalContainer: {
            paddingLeft: 15,
            // paddingTop: 10,
            display: 'flex',
            flexFlow: 'column',
            flexShrink: 1,
            // marginTop: '46px',
            minWidth: '300px',
            '& .weekly-title': {
                textAlign: 'center',
                fontSize: '14px',
                fontWeight: 700,
                margin: 0,
                padding: '2px 4px',
                borderTop: '1px solid var(--fc-border-color, #ddd)',
                borderLeft: '1px solid var(--fc-border-color, #ddd)',
                borderRight: '1px solid var(--fc-border-color, #ddd)',
            },
        },
        weeklyTotalContentWrapper: {
            flex: 1,
            display: 'flex',
            flexFlow: 'column',
            position: 'relative'
        },
        weeklyTotalContentContainer: {
            position: 'absolute',
            top: 0,
            right: 0,
            left: 0,
            bottom: 0,
            overflow: 'auto',
        },
        weekLists: {
            listStyleType: 'none',
            padding: 0,
            border: '1px solid var(--fc-border-color, #ddd)',
            flex: 1,
            flexFlow: 'column',
            display: 'flex',
            overflow: 'auto',
            margin: 0,
            '& .week-list': {
                padding: '0px 10px 10px 10px',
                borderBottom: '1px solid var(--fc-border-color, #ddd)',
                margin: 0,
                display: 'flex',
                flexFlow: 'column',
                flex: 1,
                minHeight: 143.031,
            },
            '& .week-title': {
                // textAlign: 'center',
                // borderBottom: '1px solid var(--fc-border-color, #ddd)',
                margin: '0 -10px',
                padding: '10px',
                fontWeight: 600,
                color: '#5173FF',
            },
            '& .month-total': {
                backgroundColor: 'var(--fc-event-bg-color, #5173FF)',
                color: '#fff',
            },
            '& .week-content': {
                paddingTop: 1,
                // paddingBottom: 20,
            }
        },
        totalMonth: {
            marginTop: 10
        },
        loaderWrapper: {
            display: 'flex',
            flex: 1,
            justifyContent: 'center',
            alignItems: 'center',
        },
        modalHeader: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            paddingBottom: 10,
            color: '#5173FF',
            '& > div': {
                fontSize: 21,
                display: 'flex',
                alignItems: 'center',
            }
        },
        iconButton: {
            color: '#5173FF',
        },
        toggleButtonGroup: {
            '& button.Mui-selected': {
                color: '#5173FF'
            }
        },
        calendarControl: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flex: 1,
        },
        dateControl: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            maxWidth: 350,
            flex: 1,
            '& .title': {
                fontSize: '18px',
                textAlign: 'center',
                fontWeight: 600,
            }
        },
        labelWrapper: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            '&.label-1': {
                marginRigt: '5px'
            }
        }
    })
);

const LightTooltip = withStyles((theme) => ({
    tooltip: {
      backgroundColor: theme.palette.common.white,
      color: 'rgba(0, 0, 0, 0.87)',
      boxShadow: theme.shadows[1],
      fontSize: 11,
    },
}))(Tooltip);

enum CalendarView {
    DAY_VIEW = 0,
    WEEK_VIEW,
    MONTH_VIEW
}

interface WeeklyProps {
    weekNumber: number;
    startDate: Date;
    endDate: Date;
    billableHours:    number;
    nonBillableHours: number;
    feesBilled:       number;
    feesCollected:    number;
    billableFees:     number;
}
interface StaffBudgetMaintenanceModalProps {
    open: boolean;
    onClose?: () => void;
    // tslint:disable-next-line: no-any
    data?: any;
}

interface StaffBudgetMaintenanceModalState {
    // tslint:disable-next-line: no-any
    events: any;
    weekly: WeeklyProps[];
    isLoading: boolean;
    
    startDate: Date;
    endDate: Date;

    totalBillableHours: number;
    totalNonBillableHours: number;
    totalFeesBilled: number;
    totalFeesCollected: number;
    totalBillableFees: number;

    isEditOpen: boolean;

    isDateRangeDisabled: boolean;

    formData: StaffBudgetForm;

    calendarStart: Date;
    calendarEnd: Date;

    staffFinancialYearBudget: {
        budgetDate?:               Date;
        billableHours:             number;
        nonBillableHours:          number;
        feesBilled:                number;
        feesCollected:             number;
        billableFees:              number;
        financialPeriodStartDate?: Date;
        financialPeriodEndDate?:   Date;
    } | null;

    viewType: CalendarView;

    isAddStaffBudgets: boolean;
}

export const StaffBudgetMaintenanceModal: React.FC<StaffBudgetMaintenanceModalProps> = ( props ) => {

    const staffName = props.data.name;
    const staffGuid = props.data.staffGuid;

    const calendarRef = useRef<FullCalendar>(null);

    const [monethlyBudgetSubscriptionTriggered, setMonethlyBudgetSubscriptionTriggered] = useState<boolean>(false);

    const moment = extendMoment(Moment);

    const classes = useStyles();

    const [enableBudgetSync, setEnableBudgetSync] = useState<boolean>(false);

    const staffBudgetSettingQuery = useQuery<StaffBudgetSettingsQueryData>(STAFF_BUDGET_SETTINGS_QUERY, {
        fetchPolicy: 'network-only',
        notifyOnNetworkStatusChange: true
    });

    // enable budget button when the value is true
    // tslint:disable-next-line: max-line-length

    const currencySymbol = useQuery<CurrencySymbolData>(CurrencySymbol);

    const [state, setState] = useState<StaffBudgetMaintenanceModalState>({
        events: [],
        weekly: [],
        isLoading: true,
        totalBillableHours: 0,
        totalNonBillableHours: 0,
        totalFeesBilled: 0,
        totalFeesCollected: 0,
        totalBillableFees: 0,
        startDate: new Date(),
        endDate: new Date(),
        isEditOpen: false,
        isDateRangeDisabled: false,
        calendarStart: moment().startOf('month').toDate(),
        calendarEnd: moment().endOf('month').toDate(),
        formData: {
            startDate: null,
            endDate: null,
            billableAmountPerDay: 0,
            billableHoursPerDay: 0,
            nonBillableHoursPerDay: 0,
            feesBilledPerDay: 0,
            feesCollectedPerDay: 0,
            // estimatedBudget: 0,
        },
        staffFinancialYearBudget: {
            billableHours:             0,
            nonBillableHours:          0,
            feesBilled:                0,
            feesCollected:             0,
            billableFees:              0,
        },
        viewType: CalendarView.MONTH_VIEW,
        isAddStaffBudgets: false
    });

    useEffect(() => {
        if (!staffBudgetSettingQuery.loading && !staffBudgetSettingQuery.error && staffBudgetSettingQuery.data) {
            const isEnableBudgetSync = staffBudgetSettingQuery.data.settings.applications.staffBudgetSettings.config.enableBudgetSync;
            // tslint:disable-next-line: no-console
            // console.log('isEnableBudgetSync', isEnableBudgetSync);
            setEnableBudgetSync(isEnableBudgetSync);
        }
    // tslint:disable-next-line: align
    }, [staffBudgetSettingQuery.loading, staffBudgetSettingQuery.error, staffBudgetSettingQuery.data]);

    useEffect(() => {
        if (props.open) {
            staffBudgetSettingQuery.refetch();
        }
    // tslint:disable-next-line: align
    }, [props.open]);

    useEffect(() => {
        
        const staffDailyBudgetNotificationObj = staffDailyBudgetNotification();
        const staffMonthlyBudgetNotificationObj = staffMonthlyBudgetNotification();

        return () => {
            staffDailyBudgetNotificationObj.unsubscribe();
            staffMonthlyBudgetNotificationObj.unsubscribe();
        };
    // tslint:disable-next-line: align
    }, []);

    const staffMonthlyBudgetNotification = () => {
        return (
            // let subscription =
            client
                .subscribe({
                    fetchPolicy: 'no-cache',
                    query: UPDATE_STAFF_MONTHLY_BUDGET_SUBSCRIPTION,
                    variables: {},
                })
                .subscribe({
                    // tslint:disable-next-line:no-any
                    next(results: any) {
                        if (results.data.updateStaffMonthlyBudgetNotification.status) {

                            const { updateStaffMonthlyBudgetNotification } = results.data;

                            showNotification('Success', updateStaffMonthlyBudgetNotification.message, 'info');

                            setMonethlyBudgetSubscriptionTriggered(true);

                            if (updateStaffMonthlyBudgetNotification.message.includes('Updated Successfully')) {

                                const staffDailyBudgetsParams: StaffDailyBudgetsParams = {
                                    staffGuid: staffGuid,
                                    startDate: state.calendarStart,
                                    endDate: state.calendarEnd,
                                };
                        
                                retrieveStaffDailyBudgetsData(
                                    staffDailyBudgetsParams,
                                    true,
                                    (data: StaffDailyBudgetsData) => ononDataRetrieved(data, state.calendarStart, state.calendarEnd),
                                    // tslint:disable-next-line: no-any
                                    function (reason: any): void {
                                        setMonethlyBudgetSubscriptionTriggered(false);
                                        showNotification(null, reason, 'error');
                                    }
                                );
                            }

                        } else {
                            setMonethlyBudgetSubscriptionTriggered(false);
                            showNotification('Failed', results.data.updateStaffMonthlyBudgetNotification.message, 'error');
                        }
                    },
                    // tslint:disable-next-line:no-any
                    error(err: any) {
                        // tslint:disable-next-line:no-console
                        console.error(err);
                        setMonethlyBudgetSubscriptionTriggered(false);
                        showNotification(null, err, 'error');
                    },
                })
        );
    };

    const staffDailyBudgetNotification = () => {
        return (
            // let subscription =
            client
                .subscribe({
                    fetchPolicy: 'no-cache',
                    query: StaffDailyBudgetNotificationSubscription,
                    variables: {},
                })
                .subscribe({
                    // tslint:disable-next-line:no-any
                    next(results: any) {
                        if (results.data.updateStaffDailyBudgetNotification.status) {

                            const { updateStaffDailyBudgetNotification } = results.data;

                            showNotification('Success', updateStaffDailyBudgetNotification.message, 'info');

                            if (updateStaffDailyBudgetNotification.message.includes('Updated Successfully')) {

                                const staffDailyBudgetsParams: StaffDailyBudgetsParams = {
                                    staffGuid: staffGuid,
                                    startDate: state.calendarStart,
                                    endDate: state.calendarEnd,
                                };
                        
                                retrieveStaffDailyBudgetsData(
                                    staffDailyBudgetsParams,
                                    true,
                                    (data: StaffDailyBudgetsData) => ononDataRetrieved(data, state.calendarStart, state.calendarEnd),
                                    // tslint:disable-next-line: no-any
                                    function (reason: any): void {
                                        showNotification(null, reason, 'error');
                                    }
                                );
                            }

                        } else {
                            showNotification('Failed', results.data.updateStaffDailyBudgetNotification.message, 'error');
                        }
                    },
                    // tslint:disable-next-line:no-any
                    error(err: any) {
                        // tslint:disable-next-line:no-console
                        console.error(err);
                        showNotification(null, err, 'error');
                    },
                })
        );
    };

    const onDatesSet = (arg: DatesSetArg) => {

        setState((prevState) => {
            return {
                ...prevState,
                calendarStart: arg.start,
                calendarEnd: moment(arg.end).subtract(1, 'd').toDate()
            };
        });

        const staffDailyBudgetsParams: StaffDailyBudgetsParams = {
            staffGuid: staffGuid,
            startDate: arg.start,
            endDate: moment(arg.end).subtract(1, 'd').toDate()
        };

        retrieveStaffDailyBudgetsData(
            staffDailyBudgetsParams,
            true,
            (data: StaffDailyBudgetsData) => ononDataRetrieved(data, arg.start, arg.end),
            // tslint:disable-next-line: no-any
            function (reason: any): void {
                showNotification(null, reason, 'error');
            }
        );

    };

    const ononDataRetrieved = (data: StaffDailyBudgetsData, sDate: Date, eDate: Date) => {
        
        const monthRange = moment.range(sDate, moment(eDate).subtract(1, 'd').toDate());

        // tslint:disable-next-line: no-any
        const weeks: any = [];
        const days = Array.from(monthRange.by('day'));

        days.forEach(it => {
            if (!weeks.includes(it.week())) {
                weeks.push(it.week());
            }
        });

        const staffFinancialYearBudgetData = data.staffBudget.staffDailyBudgets.staffFinancialYearBudget ?
                    data.staffBudget.staffDailyBudgets.staffFinancialYearBudget : null;

        const staffDailyBudgetData = data.staffBudget.staffDailyBudgets.staffDailyBudget.map((budget: StaffDailyBudget) => {
            return {
                ...budget,
                date: budget.budgetDate,
            };
        });

        let totalBillableHours = 0;
        let totalNonBillableHours = 0;
        let totalFeesBilled = 0;
        let totalFeesCollected = 0;
        let totalBillableFees = 0;

        staffDailyBudgetData.forEach((budget: StaffDailyBudget) => {

            // tslint:disable-next-line: max-line-length
            if (moment(budget.budgetDate).isSameOrAfter(sDate) && moment(budget.budgetDate).isSameOrBefore( moment(eDate).subtract(1, 'd').toDate())) {
                // tslint:disable-next-line: radix
                totalBillableHours += parseFloat(budget.billableHours);
                // tslint:disable-next-line: radix
                totalNonBillableHours += parseFloat(budget.nonBillableHours);
                totalFeesBilled +=       budget.feesBilled;
                totalFeesCollected +=    budget.feesCollected;
                totalBillableFees +=     budget.billableFees;
            }
        });

        // tslint:disable-next-line: no-any
        const weekly: any = [];
        
        for (let index = 0; index < weeks.length; index++) {

            const startDate = index === 0 ? sDate : moment().week(weeks[index]).startOf('week').toDate();
            // tslint:disable-next-line: max-line-length
            const endDate = index === weeks.length - 1 ? moment(eDate).subtract(1, 'd').toDate() : moment().week(weeks[index]).endOf('week').toDate();

            if (staffDailyBudgetData) {

                let billableHours = 0;
                let nonBillableHours = 0;
                let feesBilled = 0;
                let feesCollected = 0;
                let billableFees = 0;

                staffDailyBudgetData.forEach((budget: StaffDailyBudget) => {

                    if (moment(budget.budgetDate).isSameOrAfter(startDate) && moment(budget.budgetDate).isSameOrBefore(endDate)) {
                        // tslint:disable-next-line: radix
                        billableHours += parseFloat(budget.billableHours);
                        // tslint:disable-next-line: radix
                        nonBillableHours += parseFloat(budget.nonBillableHours);
                        feesBilled +=       budget.feesBilled;
                        feesCollected +=    budget.feesCollected;
                        billableFees +=     budget.billableFees;
                    }
                });

                weekly.push({
                    weekNumber: weeks[index],
                    startDate: moment(startDate).format('MMM DD, YYYY'),
                    endDate: moment(endDate).format('MMM DD, YYYY'),
                    billableHours:    billableHours,
                    nonBillableHours: nonBillableHours,
                    feesBilled:       feesBilled,
                    feesCollected:    feesCollected,
                    billableFees:     billableFees,
                });

            }
            
        }

        setState((prevState) => {
            return {
                ...prevState,
                events: staffDailyBudgetData,
                weekly: weekly,
                isLoading: false,
                totalBillableHours,
                totalNonBillableHours,
                totalFeesBilled,
                totalFeesCollected,
                totalBillableFees,
                startDate: sDate,
                endDate: moment(eDate).subtract(1, 'd').toDate(),
                formData: {
                    ...prevState.formData,
                    startDate: sDate,
                    endDate: moment(eDate).subtract(1, 'd').toDate()
                },
                staffFinancialYearBudget: staffFinancialYearBudgetData ? {
                    // tslint:disable-next-line: radix
                    billableHours:  parseFloat(staffFinancialYearBudgetData.billableHours),
                    // tslint:disable-next-line: radix
                    nonBillableHours: parseFloat(staffFinancialYearBudgetData.nonBillableHours),
                    feesBilled: staffFinancialYearBudgetData.feesBilled,
                    feesCollected: staffFinancialYearBudgetData.feesCollected,
                    billableFees: staffFinancialYearBudgetData.billableFees,
                    financialPeriodEndDate: staffFinancialYearBudgetData.financialPeriodEndDate,
                    financialPeriodStartDate: staffFinancialYearBudgetData.financialPeriodStartDate,
                } : null
            };
        });

        setMonethlyBudgetSubscriptionTriggered(false);
    };

    // tslint:disable-next-line: no-any
    const handleEventClick = (clickInfo: any) => {
        // tslint:disable-next-line: no-console
        const { billableHours, nonBillableHours, feesBilled, feesCollected, billableFees} = clickInfo.event._def.extendedProps;

        setState((prevState) => {
            return {
                ...prevState,
                formData: {
                    ...prevState.formData,
                    startDate: clickInfo.event.start,
                    endDate: clickInfo.event.start,
                    billableAmountPerDay: billableFees,
                    billableHoursPerDay: billableHours,
                    nonBillableHoursPerDay: nonBillableHours,
                    feesBilledPerDay: feesBilled,
                    feesCollectedPerDay: feesCollected,
                },
                isDateRangeDisabled: true,
                isEditOpen: enableBudgetSync ? false : true,
            };
        });

    };

    const handleDateClick = (arg: DateClickArg) => {

        // tslint:disable-next-line: no-console
        setState((prevState) => {
            return {
                ...prevState,
                formData: {
                    ...prevState.formData,
                    startDate: arg.date,
                    endDate: arg.date,
                    billableAmountPerDay: 0,
                    billableHoursPerDay: 0,
                    nonBillableHoursPerDay: 0,
                    feesBilledPerDay: 0,
                    feesCollectedPerDay: 0,
                },
                isDateRangeDisabled: true,
                isEditOpen: enableBudgetSync ? false : true,
            };
        });
    };

    // tslint:disable-next-line: no-any
    const onClickStaffBudgetForm = (isOpen: boolean) => () => {
        setState((prevState) => {
            return {
                ...prevState,
                isDateRangeDisabled: false,
                isEditOpen: isOpen,
                formData: {
                    ...prevState.formData,
                    startDate: state.startDate,
                    endDate: state.endDate,
                    billableAmountPerDay: 0,
                    billableHoursPerDay: 0,
                    nonBillableHoursPerDay: 0,
                    feesBilledPerDay: 0,
                    feesCollectedPerDay: 0,
                }
            };
        });
    };

    const onAddStaffBudgets = (isOpen: boolean) => () => {
        
        setState((prevState) => {
            return {
                ...prevState,
                isAddStaffBudgets: isOpen
            };
        });
    };

    const onToggleChange = (event: React.MouseEvent<HTMLElement>, viewType: CalendarView) => {
    
        setState((prevState) => {

            if (calendarRef && calendarRef.current) {
                const calendarApi: CalendarApi = calendarRef.current.getApi();
        
                let view: 'dayGridMonth' | 'timeGridWeek' | 'timeGridDay' = 'dayGridMonth';

                if (viewType === CalendarView.DAY_VIEW) {
                    view = 'timeGridDay';
                } else if (viewType === CalendarView.WEEK_VIEW) {
                    view = 'timeGridWeek';
                } else {
                    view = 'dayGridMonth';
                }

                calendarApi.changeView(view);
            }
            return {
                ...prevState,
                viewType: viewType
            };
        });
    };

    const onToday = () => {
        if (calendarRef && calendarRef.current) {
            const calendarApi: CalendarApi = calendarRef.current.getApi();
            calendarApi.today();
        }
    };

    const onPrevious = () => {
        if (calendarRef && calendarRef.current) {
            const calendarApi: CalendarApi = calendarRef.current.getApi();
            calendarApi.prev();
        }
    };

    const onNext = () => {
        if (calendarRef && calendarRef.current) {
            const calendarApi: CalendarApi = calendarRef.current.getApi();
            calendarApi.next();
        }
    };

    const onPreviousYear = () => {
        if (calendarRef && calendarRef.current) {
            const calendarApi: CalendarApi = calendarRef.current.getApi();
            calendarApi.prevYear();
        }
    };

    const onNextYear = () => {
        if (calendarRef && calendarRef.current) {
            const calendarApi: CalendarApi = calendarRef.current.getApi();
            calendarApi.nextYear();
        }
    };

    const formatHours = (value: number) => {

        if (value) {
            const hoursToString = `${value}`.split('.');

            const hoursExtracted = hoursToString[0];

            const minutesExtracted = value - Math.floor(value);

            let minutes = 0;

            if (minutesExtracted) {
                // tslint:disable-next-line: radix
                minutes = minutesExtracted > 0 ? minutesExtracted * 60 : 0;
            }
            const time = `${hoursExtracted}:${`${Math.round(minutes)}`.padStart(2, '0')}`;

            return time;
        }

        return undefined;
    };

    // tslint:disable-next-line: no-any
    const renderEventContent = (eventInfo: any) => {

        const data = eventInfo.event._def.extendedProps;

        return (
            <div className={classes.eventContentContainer}>
                {UserFeature.HasAccess(UserFeatureEnum.hasStaffBudgetProductionView) && (
                    <>
                        <div className="event-content">
                            <div className="label">
                                Billable Hours:
                            </div>
                            <div className="value">
                                {formatHours(parseFloat(data.billableHours))}
                            </div>
                        </div>
                        <div className="event-content">
                            <div className="label">
                                Non Billable Hours:
                            </div>
                            <div className="value">
                                {formatHours(parseFloat(data.nonBillableHours))}
                            </div>
                        </div>
                        <div className="event-content">
                            <div className="label">
                                Fees Recorded:
                            </div>
                            <div className="value">
                                {`${symbol}${formatNumber(data.billableFees)}`}
                            </div>
                        </div>
                    </>
                )}
                {UserFeature.HasAccess(UserFeatureEnum.hasStaffBudgetBillingsView) && (
                    <div className="event-content">
                        <div className="label">
                            Fees Billed:
                        </div>
                        <div className="value">
                            {`${symbol}${formatNumber(data.feesBilled)}`}
                        </div>
                    </div>
                )}
                {UserFeature.HasAccess(UserFeatureEnum.hasStaffBudgetCollectionsView) && (
                    <div className="event-content">
                        <div className="label">
                            Fees Collected:
                        </div>
                        <div className="value">
                            {`${symbol}${formatNumber(data.feesCollected)}`}
                        </div>
                    </div>
                )}
            </div>
        );
    };

    if (state.isLoading && currencySymbol.loading && staffBudgetSettingQuery.loading) {
        return (
            <Modal
                open={props.open}
                onClose={props.onClose}
            >
                 <Paper elevation={3} className={classes.root}>
                     <div className={classes.loaderWrapper}>
                        <RvLoader />
                     </div>
                 </Paper>
            </Modal>
        );
    }

    const symbol = currencySymbol.data?.options.currencySymbol.symbol ? currencySymbol.data?.options.currencySymbol.symbol : '$';

    return (
        <Modal
            open={props.open}
            onClose={props.onClose}
            className={classes.modalWrapper}
        >
            <Paper elevation={3} className={classes.root}>
                {!!state.isEditOpen && (
                    <StaffBudgetMaintenanceDailog 
                        open={state.isEditOpen}
                        onClose={onClickStaffBudgetForm(false)}
                        data={{
                            startDate: state.formData.startDate,
                            endDate: state.formData.endDate,
                            billableAmountPerDay: state.formData.billableAmountPerDay,
                            billableHoursPerDay: state.formData.billableHoursPerDay,
                            nonBillableHoursPerDay: state.formData.nonBillableHoursPerDay,
                            feesBilledPerDay: state.formData.feesBilledPerDay,
                            feesCollectedPerDay: state.formData.feesCollectedPerDay,
                            // estimatedBudget: state.formData.estimatedBudget
                        }}
                        isDateRangeDisabled={state.isDateRangeDisabled}
                        currency={symbol}
                        staffId={staffGuid}
                    />  
                )}
                {!!state.isAddStaffBudgets && (
                    <StaffNameBudgetDialog 
                        open={state.isAddStaffBudgets}
                        onClose={onAddStaffBudgets(false)}
                        staffGuid={props.data.staffGuid}
                        staffName={staffName}
                        currencySymbol={symbol}
                        isSubcriptionTriggered={monethlyBudgetSubscriptionTriggered}
                        enableBudgetSync={enableBudgetSync}
                    />
                )}
                <div className={classes.modalHeader}>
                    <div>
                        Budgets: {staffName}
                    </div>
                    <div className={classes.calendarControl}>
                        <div className={classes.dateControl}>
                            <LightTooltip title="Previous Year">
                                <IconButton onClick={onPreviousYear}>
                                    <ChevronLeftIcon />
                                </IconButton>
                            </LightTooltip>
                            <LightTooltip title="Previous">
                                <IconButton onClick={onPrevious}>
                                    <ChevronLeftIcon />
                                </IconButton>
                            </LightTooltip>
                            <div className="title">{calendarRef.current ? calendarRef.current.getApi().view.title : ''}</div>
                            <LightTooltip title="Next">
                                <IconButton onClick={onNext}>
                                    <ChevronRightIcon />
                                </IconButton>
                            </LightTooltip>
                            <LightTooltip title="Next Year">
                                <IconButton onClick={onNextYear}>
                                    <ChevronRightIcon />
                                </IconButton>
                            </LightTooltip>
                        </div>
                    </div>
                    <div>
                        
                        <LightTooltip title="Budget Table">
                            <IconButton 
                                className={classes.iconButton}
                                onClick={onAddStaffBudgets(true)}
                            >
                                <ReorderIcon />
                            </IconButton>
                        </LightTooltip>

                        {HasBudgetEditPermission() && !enableBudgetSync && (
                            <LightTooltip title="Edit">
                                <IconButton 
                                    className={classes.iconButton}
                                    onClick={onClickStaffBudgetForm(true)}
                                >
                                    <EditIcon />
                                </IconButton>
                            </LightTooltip>
                        )}
                        {/* <LightTooltip title="Delete">
                            <IconButton 
                                className={classes.iconButton}
                            >
                                <DeleteIcon />
                            </IconButton>
                        </LightTooltip> */}
                        <LightTooltip title="Today">
                            <IconButton 
                                className={classes.iconButton}
                                onClick={onToday}
                            >
                                <TodayIcon />
                            </IconButton>
                        </LightTooltip>
                        <ToggleButtonGroup 
                            size="small" 
                            value={state.viewType} 
                            exclusive={true} 
                            onChange={onToggleChange}
                            className={classes.toggleButtonGroup}
                        >
                            <ToggleButton value={CalendarView.DAY_VIEW}>
                                <LightTooltip title="Day View">
                                    <EventIcon />
                                </LightTooltip>
                            </ToggleButton>
                            <ToggleButton value={CalendarView.WEEK_VIEW}>
                                <LightTooltip title="Week View">
                                    <ViewWeekIcon />
                                </LightTooltip>
                            </ToggleButton>
                        
                            <ToggleButton value={CalendarView.MONTH_VIEW}>
                                <LightTooltip title="Month View">   
                                    <DateRangeIcon />
                                </LightTooltip>
                            </ToggleButton>
                        </ToggleButtonGroup>
                    </div>
                </div>
                <div className={classes.calendarWrapper}>
                    <IconButton 
                        className={classes.closeButton}
                        onClick={props.onClose}
                    >
                        <CancelIcon />
                    </IconButton>
                    <div className={classes.calendarContainer}>
                        <FullCalendar
                            plugins={[ dayGridPlugin, timeGridPlugin, interactionPlugin ]}
                            initialView="dayGridMonth"
                            headerToolbar={{
                                left: undefined, // 'title',
                                center: undefined,
                                right: undefined // 'today,prev,next' // 'dayGridMonth,timeGridWeek,timeGridDay'
                            }}
                            dateClick={handleDateClick}
                            datesSet={onDatesSet}
                            events={state.events}
                            eventContent={renderEventContent}
                            // editable={true}
                            selectable={true}
                            selectMirror={true}
                            dayMaxEvents={true}
                            eventClick={handleEventClick}
                            fixedWeekCount={false}
                            showNonCurrentDates={false}
                            ref={calendarRef}
                        />
                    </div>
                    <div className={classes.weeklyTotalContainer}>
                        <h3 className="weekly-title">Total</h3>
                        <div className={classes.weeklyTotalContentWrapper}>
                            <div className={classes.weeklyTotalContentContainer}>
                                <ul className={classes.weekLists}>
                                    {state.weekly.map((week: WeeklyProps, index: number) => (
                                        <li className="week-list">
                                            <div className="week-title">{`Week ${index + 1} (${week.startDate} - ${week.endDate})`}</div>
                                            <div className="week-content">
                                                {UserFeature.HasAccess(UserFeatureEnum.hasStaffBudgetProductionView) && (
                                                    <>
                                                        <div className={classes.labelWrapper}>
                                                            <div className="label-1">Billable Hours:</div>
                                                            <div className="value-1">{formatHours(week.billableHours)}</div>
                                                        </div>
                                                        <div className={classes.labelWrapper}>
                                                            <div className="label-1">Non Billable Hours:</div>
                                                            <div className="value-1">{formatHours(week.nonBillableHours)}</div>
                                                        </div>
                                                        <div className={classes.labelWrapper}>
                                                            <div className="label-1">Fees Recorded:</div>
                                                            <div className="value-1">{`${symbol}${formatNumber(Math.round(week.billableFees))}`}</div>
                                                        </div>
                                                    </>
                                                )}
                                                {UserFeature.HasAccess(UserFeatureEnum.hasStaffBudgetBillingsView) && (
                                                    <div className={classes.labelWrapper}>
                                                        <div className="label-1">Fees Billed:</div>
                                                        <div className="value-1">{`${symbol}${formatNumber(Math.round(week.feesBilled))}`}</div>
                                                    </div>
                                                )}
                                                {UserFeature.HasAccess(UserFeatureEnum.hasStaffBudgetCollectionsView) && (
                                                    <div className={classes.labelWrapper}>
                                                        <div className="label-1">Fees Collected:</div>
                                                        <div className="value-1">{`${symbol}${formatNumber(Math.round(week.feesCollected))}`}</div>
                                                    </div>
                                                )}
                                            </div>
                                        </li>
                                    ))}
                                </ul>
                                <ul className={`${classes.weekLists} ${classes.totalMonth}`}>
                                    <li className="week-list">
                                        <div className="week-title month-total">{`${moment(state.startDate).format('MMMM')} Totals`}</div>
                                        <div className="week-content">
                                            {UserFeature.HasAccess(UserFeatureEnum.hasStaffBudgetProductionView) && (
                                                <>
                                                    <div className={classes.labelWrapper}>
                                                        <div className="label-1">Billable Hours:</div>
                                                        <div className="value-1">{formatHours(state.totalBillableHours)}</div>
                                                    </div>
                                                    <div className={classes.labelWrapper}>
                                                        <div className="label-1">Non Billable Hours:</div>
                                                        <div className="value-1">{formatHours(state.totalNonBillableHours)}</div>
                                                    </div>
                                                    <div className={classes.labelWrapper}>
                                                        <div className="label-1">Fees Recorded:</div>
                                                        <div className="value-1">{`${symbol}${formatNumber(Math.round(state.totalBillableFees))}`}</div>
                                                    </div>
                                                </>
                                            )}
                                            {UserFeature.HasAccess(UserFeatureEnum.hasStaffBudgetBillingsView) && (
                                                <div className={classes.labelWrapper}>
                                                    <div className="label-1">Fees Billed:</div>
                                                    <div className="value-1">{`${symbol}${formatNumber(Math.round(state.totalFeesBilled))}`}</div>
                                                </div>
                                            )}
                                            {UserFeature.HasAccess(UserFeatureEnum.hasStaffBudgetCollectionsView) && (
                                                <div className={classes.labelWrapper}>
                                                    <div className="label-1">Fees Collected:</div>
                                                    <div className="value-1">{`${symbol}${formatNumber(Math.round(state.totalFeesCollected))}`}</div>
                                                </div>
                                            )}
                                        </div>
                                    </li>
                                </ul>
                                {state.staffFinancialYearBudget && (
                                    <ul className={`${classes.weekLists} ${classes.totalMonth}`}>
                                        <li className="week-list">
                                            <div className="week-title month-total">{`Year Totals (${moment(state.staffFinancialYearBudget.financialPeriodStartDate).format('MMMM')} to ${moment(state.staffFinancialYearBudget.financialPeriodEndDate).format('MMMM')})`}</div>
                                            <div className="week-content">
                                                {UserFeature.HasAccess(UserFeatureEnum.hasStaffBudgetProductionView) && (
                                                    <>
                                                        <div className={classes.labelWrapper}>
                                                            <div className="label-1">Billable Hours:</div>
                                                            <div className="value-1">{formatHours(state.staffFinancialYearBudget.billableHours)}</div>
                                                        </div>
                                                        <div className={classes.labelWrapper}>
                                                            <div className="label-1">Non Billable Hours:</div>
                                                            <div className="value-1">{formatHours(state.staffFinancialYearBudget.nonBillableHours)}</div>
                                                        </div>
                                                        <div className={classes.labelWrapper}>
                                                            <div className="label-1">Fees Recorded:</div>
                                                            <div className="value-1">{`${symbol}${formatNumber(Math.round(state.staffFinancialYearBudget.billableFees))}`}</div>
                                                        </div>
                                                    </>
                                                )}
                                                {UserFeature.HasAccess(UserFeatureEnum.hasStaffBudgetBillingsView) && (
                                                    <div className={classes.labelWrapper}>
                                                        <div className="label-1">Fees Billed:</div>
                                                        <div className="value-1">{`${symbol}${formatNumber(Math.round(state.staffFinancialYearBudget.feesBilled))}`}</div>
                                                    </div>
                                                )}
                                                {UserFeature.HasAccess(UserFeatureEnum.hasStaffBudgetCollectionsView) && (
                                                     <div className={classes.labelWrapper}>
                                                        <div className="label-1">Fees Collected:</div>
                                                        <div className="value-1">{`${symbol}${formatNumber(Math.round(state.staffFinancialYearBudget.feesCollected))}`}</div>
                                                    </div>
                                                )}
                                            </div>
                                        </li>
                                    </ul>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </Paper>
        </Modal>
    );
};

const StaffDailyBudgetNotificationSubscription = gql`
    subscription staffDailyBudgetNotification {
        updateStaffDailyBudgetNotification{
            id,
            status,
            message,
            description
        }
    }
`;

const UPDATE_STAFF_MONTHLY_BUDGET_SUBSCRIPTION = gql`
    subscription UpdateStaffMonthlyBudgetSubscription{
        updateStaffMonthlyBudgetNotification {
            id,
            description,
            message,
            status
        }
    }
`;

export interface StaffBudgetSettingsQueryData {
    settings: Settings;
}

export interface Settings {
    applications: Applications;
}

export interface Applications {
    staffBudgetSettings: StaffBudgetSettings;
}

export interface StaffBudgetSettings {
    config: Config;
}

export interface Config {
    enableBudgetSync: boolean;
}

const STAFF_BUDGET_SETTINGS_QUERY = gql`
     query StaffBudgetSettingsQuery {
        settings {
            applications {
                staffBudgetSettings {
                    config {
                        enableBudgetSync
                    }
                }
            }
        }
    }
`;