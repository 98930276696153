/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Drawer, Grid, makeStyles, Typography } from '@material-ui/core';
import React, { useEffect } from 'react';
import { Field, Form, FormSpy } from 'react-final-form';
import { FormApi, SubmissionErrors } from 'final-form';
import { StaffSelectorAutoComplete } from '../selectors/StaffSelectorAutoComplete';
import { IAutoCompleteItem } from '../../typings/autoComplete';
import { KeyboardDatePicker } from '@material-ui/pickers/DatePicker/DatePicker';
import gql from 'graphql-tag';
import { useMutation } from 'react-apollo';
import { showNotification } from '../../App';
import moment from 'moment';

const useStyles = makeStyles({
    paperRoot: {
        maxWidth: '650px',
        padding: '15px'
    },
    textField: {
        width: '100%'
    },
    butotnContainer: {
        display: 'flex',
        alignItems: 'center',
        margin: '0 -10px',
        justifyContent: 'flex-end'
    },
    actionButton: {
        margin: '0 10px'
    },
    title: {
        fontSize: '14px',
        fontWeight: 600
    }
});

interface EditStaffBudgetDrawerProps {
    open: boolean;
    onClose?: () => void;
    // tslint:disable-next-line: no-any
    data: any;
}

interface EditStaffBudgetForm {
    partyGuid: string;
    startDate: Date | null;
    endDate: Date | null;
    staffClassification: IAutoCompleteItem | null;
}

export const EditStaffBudgetDrawer: React.FC<EditStaffBudgetDrawerProps> = (props) => {

    const classes = useStyles();

    const [formState] = React.useState<EditStaffBudgetForm>({
        partyGuid: props.data.staffGuid,
        startDate: props.data.startDate ? props.data.startDate : null,
        endDate: props.data.endDate ? props.data.endDate : null,
        staffClassification: props.data.staffRole ? { label: props.data.staffRole, value: props.data.staffRole } : null
    });

    // tslint:disable-next-line: no-any
    const [updateStaff, updateStaffProperty] = useMutation<any, UpdateStaffMutationParams>(UPDATE_STAFF_MUTATION);

    useEffect(() => {
        if (updateStaffProperty.data && updateStaffProperty.data.updateStaff) {
            showNotification(null, 'Successfully submitted', 'info');  
            if (props.onClose) {
                props.onClose();
            }
        }
    // tslint:disable-next-line: align
    }, [updateStaffProperty.data]);

    // tslint:disable-next-line: no-any
    // const required = (value: any) => {
    //     return value ? undefined : 'Required';
    // };

    // tslint:disable-next-line: no-any
    const onSubmit = (values: EditStaffBudgetForm, form: FormApi<EditStaffBudgetForm>, callback?: (errors?: SubmissionErrors) => void) => {
        updateStaff({
            variables: {
                partyGuid: values.partyGuid,
                endDate: values.endDate ? moment(values.endDate).toDate() : values.endDate,
                staffRole: values.staffClassification ? values.staffClassification.value : null,
                startDate: values.startDate ? moment(values.startDate).toDate() : values.startDate,
                updateFromRedView: true
            }
        });
    };

    const actionButton = (
        form: FormApi<EditStaffBudgetForm>,
        submitting: boolean,
        pristine: boolean,
        // tslint:disable-next-line: no-any
        values: any
    ) => {
        return (
            <div className={classes.butotnContainer}>
                <Button 
                    type="submit" 
                    color="primary"
                    disabled={submitting || pristine || updateStaffProperty.loading}
                    variant="contained"
                    className={classes.actionButton}
                >
                    Update
                </Button>
                <Button 
                    onClick={props.onClose} 
                    color="primary"
                    variant="contained"
                    className={classes.actionButton}
                >
                    Cancel
                </Button>
            </div>
        );
    };

    return (
        <Drawer 
            anchor="right" 
            open={props.open} 
            onClose={props.onClose}
            classes={{
                paper: classes.paperRoot
            }}
        >
            <Form
                onSubmit={onSubmit}
                initialValues={formState}
                initialValuesEqual={() => true}
                keepDirtyOnReinitialize={true}
                // validate={validate}
                render={({ 
                    handleSubmit,
                    form,
                    submitting,
                    pristine,
                    values
                 }) => (
                    <form onSubmit={handleSubmit}>
                        <Grid container={true} spacing={3}>
                            <Grid item={true} xs={12} sm={12} md={12} lg={12}>
                                <Typography className={classes.title}>Edit Staff Details</Typography>
                            </Grid>
                            <FormSpy subscription={{values: true, validating: true}}>
                                {/* tslint:disable-next-line: no-shadowed-variable */}
                                {({ values }) => (
                                    <>
                                        <Grid item={true} xs={12} sm={12} md={6} lg={6}>
                                            <Field
                                                name="startDate"
                                                // validate={form.getFieldState('startDate')?.value !== null ? required : undefined}
                                            >
                                                {({ input, meta }) => (
                                                    <KeyboardDatePicker
                                                        {...input}
                                                        value={input.value ? input.value : null}
                                                        className={classes.textField}
                                                        id="startDate"
                                                        name="startDate"
                                                        label="Start Date"
                                                        format="DD/MM/YYYY"
                                                        placeholder="dd/mm/yyyy"
                                                        animateYearScrolling={true}
                                                        // tslint:disable-next-line: max-line-length
                                                        autoOk={true}
                                                        allowKeyboardControl={true}
                                                        variant={'inline'}
                                                        error={meta.error && meta.touched}
                                                        inputProps={{autoComplete: 'off'}}
                                                        InputLabelProps={{ shrink: true }}
                                                        helperText={
                                                            meta.error &&
                                                            meta.touched
                                                                ? 'Start date is required'
                                                                : ''
                                                        }
                                                        initialFocusedDate={new Date().setHours(12, 0, 0, 0)}
                                                    />
                                                )}
                                            </Field>
                                        </Grid>
                                        <Grid item={true} xs={12} sm={12} md={6} lg={6}>
                                            <Field
                                                name="endDate"
                                                // validate={form.getFieldState('endDate')?.value !== null ? required : undefined}
                                            >
                                                {({ input, meta }) => (
                                                    <KeyboardDatePicker
                                                        {...input}
                                                        value={input.value ? input.value : null}
                                                        className={classes.textField}
                                                        id="endDate"
                                                        name="endDate"
                                                        label="End Date"
                                                        format="DD/MM/YYYY"
                                                        placeholder="dd/mm/yyyy"
                                                        animateYearScrolling={true}
                                                        // tslint:disable-next-line: max-line-length
                                                        autoOk={true}
                                                        allowKeyboardControl={true}
                                                        variant={'inline'}
                                                        error={meta.error && meta.touched}
                                                        inputProps={{autoComplete: 'off'}}
                                                        InputLabelProps={{ shrink: true }}
                                                        helperText={
                                                            meta.error &&
                                                            meta.touched
                                                                ? 'End date is required'
                                                                : ''
                                                        }
                                                        minDate={values.startDate ? values.startDate : undefined}
                                                        initialFocusedDate={new Date().setHours(12, 0, 0, 0)}
                                                    />
                                                )}
                                            </Field>
                                        </Grid>
                                    </>
                                )}
                            </FormSpy>
                            <Grid item={true} xs={12} sm={12} md={6} lg={6}>
                                <Field
                                    name="staffClassification"
                                    // validate={required}
                                    // required={false}
                                    // validate={form.getFieldState('priority')?.value !== undefined ? required : undefined}
                                >
                                    {({ input, meta }) => (
                                        <StaffSelectorAutoComplete
                                            {...input}
                                            label="Staff Classification"
                                            value={input.value ? input.value : null}
                                            // required={true}
                                            className={`${classes.textField}`}
                                            error={meta.error && meta.touched}
                                                // tslint:disable-next-line: jsx-alignment
                                            onSelection={(selection: IAutoCompleteItem, name: string) => 
                                                input.onChange(selection)
                                            }
                                            helperText={
                                                meta.error &&
                                                meta.touched
                                                    ? 'Staff Classification is required'
                                                    : ''
                                            }
                                            disablePortal={false}
                                        />
                                    )}
                                </Field>
                            </Grid>
                            <Grid item={true} xs={12} sm={12} md={12} lg={12}>
                                {actionButton(form, submitting, pristine, values)}
                            </Grid>
                        </Grid>
                    </form>
                )}
            />
        </Drawer>
    );
};

interface UpdateStaffMutationParams {
    partyGuid: string;
    startDate: Date | null;
    endDate: Date | null;
    staffRole: string | null;
    updateFromRedView: Boolean;
}

const UPDATE_STAFF_MUTATION = gql`
    mutation UpdateStaffMutation($partyGuid: String, $startDate: DateTime, $endDate: DateTime, $staffRole: String, $updateFromRedView: Boolean) {
        updateStaff(
            partyGuid: $partyGuid,
            startDate: $startDate,
            endDate: $endDate,
            staffRole: $staffRole,
            updateFromRedView: $updateFromRedView
        )
    }
`;