/* eslint-disable react-hooks/exhaustive-deps */
// tslint:disable-next-line: max-line-length
import { makeStyles, Theme, createStyles, Dialog, DialogTitle, DialogContent, DialogActions, Button, Grid, FormControl, FormGroup, FormControlLabel, Checkbox, Typography, RadioGroup, Radio, Divider, Paper, CircularProgress } from '@material-ui/core';
import { FormApi, SubmissionErrors } from 'final-form';
import React, { useEffect, useState } from 'react';
import { AnyObject, Field, Form, FormSpy, FieldInputProps } from 'react-final-form';
import DialogBox from '../../Dashboard/Component/DialogBox';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';
import { mainTheme } from '../../Theme';
import { CurrencyInput2 } from '../../components/CurrencyInput';
import { FinancialYearAutoComplete, FinancialYearItem } from '../selectors/FinancialYearAutoComplete';
import { showNotification } from '../../App';
import Moment from 'moment';
import { extendMoment } from 'moment-range';
// import { formatNumber } from '../../helpers/ChartHelper';
import _ from 'lodash';
import { formatNumber } from '../../helpers/ChartHelper';
import gql from 'graphql-tag';
import { client } from '../..';
import { FetchPolicy } from 'apollo-client';
import LoadingDialog from '../../components/LoadingDialog';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            position: 'relative',
        },
        buttonContainer: {
            display: 'flex',
            justifyContent: 'space-between',
            // paddingBottom: '20px'
        },
        button: {
            margin: theme.spacing(1),
            minWidth: '83px',
        },
        hidden: {
            display: 'none',
        },
        radioGroup: {
            flexDirection: 'row'
        },
        radioIcon: {
            color: mainTheme.BrandColors.TemplatePrimary
        },
        textField: {
            width: '100%'
        },
        amountField: {
            width: '100%',
            maxWidth: '100px',
            '& .MuiInputBase-input': {
                textAlign: 'right'
            }, 
            '& .MuiFormHelperText-root': {
                textAlign: 'right'
            }
        },
        weekWrapper: {
            display: 'flex',
            alignItems: 'flex-start',
            flexFlow: 'column wrap'
        },
        paper: {
            padding: '15px'
        },
        gridContainer: {
            display: 'flex',
            alignItems: 'center',
            margin: '0 -10px',
        },
        gridItem: {
            margin: '0 10px',
            flex: 1
        },
        gridItem1: {
            flex: 1
        },
        loadingWrapper: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexFlow: 'column wrap',
            width: '100%'
        },
        loadingTitle: {
            fontStyle: 'italic',
            paddingTop: '10px'
        },
        textRight: {
            textAlign: 'right'
        }
    })
);
interface StaffNameBudgetDialogProps {
    open: boolean;
    onClose?: () => void;
    staffGuid: string;
    currencySymbol: string;
    staffName: string;
    isSubcriptionTriggered?: boolean;
    enableBudgetSync: boolean;
}

interface FinancialCategory {
    monthStartDate?: string;
    billableHoursPerDay?: {
        value: number;
        isChanged: boolean;
    };
    nonBillableHoursPerDay?: {
        value: number;
        isChanged: boolean;
    };
    feesRecorded?: {
        value: number;
        isChanged: boolean;
    };
    feesBilled?: {
        value: number;
        isChanged: boolean;
    };
    feesCollected?: {
        value: number;
        isChanged: boolean;
    };
    realisation?: {
        value: number;
        isChanged: boolean;
    };
    collectability?: {
        value: number;
        isChanged: boolean;
    };
}

interface StaffNameBudgetDialogForm {
    financialType: 'monthly' | 'annually';
    financialYear: FinancialYearItem | null;
    dateRange: Moment.Moment[];
    week: {
        sunday: boolean;
        monday: boolean;
        tuesday: boolean;
        wednesday: boolean;
        thursday: boolean;
        friday: boolean;
        saturday: boolean;
    };
    annual: FinancialCategory;
    july: FinancialCategory;
    august: FinancialCategory;
    september: FinancialCategory;
    october: FinancialCategory;
    november: FinancialCategory;
    december: FinancialCategory;
    january: FinancialCategory;
    february: FinancialCategory;
    march: FinancialCategory;
    april: FinancialCategory;
    may: FinancialCategory;
    june: FinancialCategory;
}

export const StaffNameBudgetDialog: React.FC<StaffNameBudgetDialogProps> = (props) => {

    const classes = useStyles();

    const moment = extendMoment(Moment);

    const [onSubmitLoading, setOnSubmitLoading] = useState<boolean>(false);

    const [isFormChanged, setIsFormChanged] = useState<boolean>(false);

    const [showDiscard, setShowDiscard] = useState<boolean>(false);

    const [generateMonthLoading, setGenerateMonthLoading] = useState<boolean>(true);

    const [formState] = useState<StaffNameBudgetDialogForm>({
        dateRange: [],
        financialType: 'monthly',
        financialYear: null,
        week: {
            sunday: false,
            monday: true,
            tuesday: true,
            wednesday: true,
            thursday: true,
            friday: true,
            saturday: false,
        },
        annual: {},
        july: {},
        august: {},
        september: {},
        october: {},
        november: {},
        december: {},
        january: {},
        february: {},
        march: {},
        april: {},
        may: {},
        june: {},
    });

    useEffect(() => {
        // use to close the entry budget form when subscription triggered
        if (props.isSubcriptionTriggered === true) {
            setOnSubmitLoading(false);
            if (props.onClose) {
                props.onClose();
            }
        }
    // tslint:disable-next-line: align
    }, [props.isSubcriptionTriggered]);

    const onClose = () => {
        if (isFormChanged) {
            setShowDiscard(true);
        } else {
            if (props.onClose) {
                props.onClose();
            }
        }
    };

    const onReset = () => {
        // reset
    };

    const updateStaffDailBudget = (monthlyInput: StaffMonthlyBudgetsInput[], workingDaysInfput: StaffWorkingDaysInput) => {

        // tslint:disable-next-line: no-any
        client.mutate<any, StaffMonthlyBudgetInputParams>({
            mutation: UPDATE_STAFF_MONTHLY_BUDGET_MUTATION,
            variables: {
                staffGuid: props.staffGuid,
                staffMonthlyBudgetInput: {
                    staffMonthlyBudgetsInput: monthlyInput
                },
                staffWorkingDaysInput: workingDaysInfput
            },
        })
        // tslint:disable-next-line: no-any
        .then((results: { data: any; }) => { 
            if (results.data) {
                if (results.data.error === null || results.data.error === undefined) {
                    showNotification(null, 'Successfully submitted', 'info'); 

                    // setOnSubmitLoading(false);

                    // if (props.onClose) {
                    //     props.onClose();
                    // }
                } else {
                    showNotification('Failed to update staff daily budget.', results.data.error, 'error'); 
                }
            }            
        })
        // tslint:disable-next-line:no-any
        .catch((reason: any) => { showNotification('Failed to update staff daily budget.', reason, 'error'); });   
    };

    const onSubmit = (values: StaffNameBudgetDialogForm, form: FormApi<StaffNameBudgetDialogForm, StaffNameBudgetDialogForm>, callback?: (errors?: SubmissionErrors) => void) => {
        
        setOnSubmitLoading(true);

        // less of all working days and put it on array
        // const allowedDays = [
        //     values.week.monday ? 1 : undefined,
        //     values.week.tuesday ? 2 : undefined,
        //     values.week.wednesday ? 3 : undefined,
        //     values.week.thursday ? 4 : undefined,
        //     values.week.friday ? 5 : undefined,
        //     values.week.saturday ? 6 : undefined,
        //     values.week.sunday ? 7 : undefined
        // ].filter((tempVal: number) => tempVal !== undefined);

        // console.log('allowedDays', allowedDays);

        let staffWorkingDaysInput: StaffWorkingDaysInput = {            
            financialYearStartDate: moment(values.financialYear!.startDate).format('YYYY-MM-DD'),
            monday: values.week.monday,
            tuesday: values.week.tuesday,
            wednesday: values.week.wednesday,
            thursday: values.week.thursday,
            friday: values.week.friday,
            saturday: values.week.saturday,
            sunday: values.week.sunday,
        };

        // object that need to remove just toget only the object for months and annually
        const defaultExclude = ['financialType', 'financialYear', 'week', 'dateRange'];

        // removed unuse property
        const remainingProperty = _.omit(values, [...defaultExclude]);

        let monthlyBudgets = new Map();

        monthlyBudgets.set('january', remainingProperty.january);
        monthlyBudgets.set('february', remainingProperty.february);
        monthlyBudgets.set('march', remainingProperty.march);
        monthlyBudgets.set('april', remainingProperty.april);
        monthlyBudgets.set('may', remainingProperty.may);
        monthlyBudgets.set('june', remainingProperty.june);
        monthlyBudgets.set('july', remainingProperty.july);
        monthlyBudgets.set('august', remainingProperty.august);
        monthlyBudgets.set('september', remainingProperty.september);
        monthlyBudgets.set('october', remainingProperty.october);
        monthlyBudgets.set('november', remainingProperty.november);
        monthlyBudgets.set('december', remainingProperty.december);

        // let monthlyBudgets = new Set([remainingProperty.january, remainingProperty.february, remainingProperty.march, remainingProperty.april, remainingProperty.may,
        //     remainingProperty.june, remainingProperty.july, remainingProperty.august, remainingProperty.september, remainingProperty.october, remainingProperty.november,
        //     remainingProperty.december]);

        let monthlyBudgetsWithChanges: StaffMonthlyBudgetsInput[] = [];        

        monthlyBudgets.forEach((monthlyBudget, key) => {

            if (monthlyBudget?.billableHoursPerDay?.isChanged ||
                monthlyBudget?.nonBillableHoursPerDay?.isChanged ||
                monthlyBudget?.feesRecorded?.isChanged ||
                monthlyBudget?.feesBilled?.isChanged ||
                monthlyBudget?.feesCollected?.isChanged) {
                    monthlyBudgetsWithChanges.push(
                        {
                            monthStartDate: monthlyBudget?.monthStartDate, // Get MonthStartDate
                            chargeableHours: monthlyBudget?.billableHoursPerDay?.value,
                            nonChargeableHours: monthlyBudget?.nonBillableHoursPerDay?.value,
                            feesBilled: monthlyBudget?.feesBilled?.value,
                            feesCollected: monthlyBudget?.feesCollected?.value,
                            feesRecorded: monthlyBudget?.feesRecorded?.value
                        });
                }
        });

        updateStaffDailBudget(monthlyBudgetsWithChanges, staffWorkingDaysInput);

        // We only need the months which are changed

        // extract all the months
        // values.dateRange.forEach((financialYearDate: Moment.Moment) => {
        //     // get the first date of the month
        //     const startMonthDate = moment(financialYearDate).startOf('month').toDate();
        //     // get the last date of the month
        //     const endMonthDate = moment(financialYearDate).endOf('month').toDate();
            
        //     const monthRange = moment.range(startMonthDate, endMonthDate);

        //     // get all days by from start to end date
        //     const days = Array.from(monthRange.by('day'));

        //     // get remaining days by week day selected
        //     const remainingDays = days.filter((day: Moment.Moment) => allowedDays.includes(moment(day).isoWeekday()));

        //     // console.log('remainingDays', remainingDays.length);

        //     remainingDays.forEach((remainingDay: Moment.Moment) => {
        //         // console.log('remainingDay', remainingDay);
                
        //         // convert the date to selected format
        //         const budgetDate = moment(remainingDay).format('MM/DD/YYYY');

        //         // get the name of the month
        //         const monthName = moment(financialYearDate).format('MMMM').toLowerCase();

        //         const billableHoursPerDay = values[monthName].billableHoursPerDay;
        //         const nonBillableHoursPerDay = values[monthName].nonBillableHoursPerDay;

        //         // divide to ramaining days base on weekdays selected to get the fund per day
        //         const feesRecorded = values[monthName].feesRecorded / remainingDays.length;
        //         const feesBilled = values[monthName].feesBilled / remainingDays.length;
        //         const feesCollected = values[monthName].feesCollected / remainingDays.length;

        //         staffDailyBudgetInput.push({
        //             budgetDate: budgetDate,
        //             chargeableHours: billableHoursPerDay,
        //             nonChargeableHours: nonBillableHoursPerDay,
        //             feesBilled: feesBilled,
        //             feesCollected: feesCollected,
        //             feesRecorded: feesRecorded,
        //             staffId: props.staffGuid
        //         });

        //     });
        // });

    };

    const onValidate = (values: StaffNameBudgetDialogForm) => {
        let errors = {
            annual: {
                feesRecorded: {value: ''},
                feesBilled: {value: ''},
                feesCollected: {value: ''},
                billableHoursPerDay: {value: ''},
                nonBillableHoursPerDay: {value: ''},
                // realisation: '',
                // collectability: ''
            },
            january: {
                feesRecorded: {value: ''},
                feesBilled: {value: ''},
                feesCollected: {value: ''},
                billableHoursPerDay: {value: ''},
                nonBillableHoursPerDay: {value: ''},
                // realisation: '',
                // collectability: ''
            },
            february: {
                feesRecorded: {value: ''},
                feesBilled: {value: ''},
                feesCollected: {value: ''},
                billableHoursPerDay: {value: ''},
                nonBillableHoursPerDay: {value: ''},
                // realisation: '',
                // collectability: ''
            },
            march: {
                feesRecorded: {value: ''},
                feesBilled: {value: ''},
                feesCollected: {value: ''},
                billableHoursPerDay: {value: ''},
                nonBillableHoursPerDay: {value: ''},
                // realisation: '',
                // collectability: ''
            },
            april: {
                feesRecorded: {value: ''},
                feesBilled: {value: ''},
                feesCollected: {value: ''},
                billableHoursPerDay: {value: ''},
                nonBillableHoursPerDay: {value: ''},
                // realisation: '',
                // collectability: ''
            },
            may: {
                feesRecorded: {value: ''},
                feesBilled: {value: ''},
                feesCollected: {value: ''},
                billableHoursPerDay: {value: ''},
                nonBillableHoursPerDay: {value: ''},
                // realisation: '',
                // collectability: ''
            },
            june: {
                feesRecorded: {value: ''},
                feesBilled: {value: ''},
                feesCollected: {value: ''},
                billableHoursPerDay: {value: ''},
                nonBillableHoursPerDay: {value: ''},
                // realisation: '',
                // collectability: ''
            },
            july: {
                feesRecorded: {value: ''},
                feesBilled: {value: ''},
                feesCollected: {value: ''},
                billableHoursPerDay: {value: ''},
                nonBillableHoursPerDay: {value: ''},
                // realisation: '',
                // collectability: ''
            },
            august: {
                feesRecorded: {value: ''},
                feesBilled: {value: ''},
                feesCollected: {value: ''},
                billableHoursPerDay: {value: ''},
                nonBillableHoursPerDay: {value: ''},
                // realisation: '',
                // collectability: ''
            },
            september: {
                feesRecorded: {value: ''},
                feesBilled: {value: ''},
                feesCollected: {value: ''},
                billableHoursPerDay: {value: ''},
                nonBillableHoursPerDay: {value: ''},
                // realisation: '',
                // collectability: ''
            },
            october: {
                feesRecorded: {value: ''},
                feesBilled: {value: ''},
                feesCollected: {value: ''},
                billableHoursPerDay: {value: ''},
                nonBillableHoursPerDay: {value: ''},
                // realisation: '',
                // collectability: ''
            },
            november: {
                feesRecorded: {value: ''},
                feesBilled: {value: ''},
                feesCollected: {value: ''},
                billableHoursPerDay: {value: ''},
                nonBillableHoursPerDay: {value: ''},
                // realisation: '',
                // collectability: ''
            },
            december: {
                feesRecorded: {value: ''},
                feesBilled: {value: ''},
                feesCollected: {value: ''},
                billableHoursPerDay: {value: ''},
                nonBillableHoursPerDay: {value: ''},
                // realisation: '',
                // collectability: ''
            }
        };

        // object that need to remove just toget only the object for months and annually
        const defaultExclude = ['financialType', 'financialYear', 'week', 'dateRange'];

        // removed unuse property
        const remainingProperty = _.omit(errors, [...defaultExclude]);

        // annual validation
        if (values.financialType === 'annually') {

            // only get the annual in the object
            const annualObject = _.pick(remainingProperty, 'annual');
            
            if (annualObject.annual && Object.keys(annualObject.annual).length) {

                const convertAnnualToArray = Object.keys(annualObject.annual);
                
                let totalFieldEmpty = 0;

                // check 1 field from annual if has value 
                convertAnnualToArray.forEach((financialCategory: string) => {     
                    // tslint:disable-next-line: max-line-length
                    if (!values.annual.hasOwnProperty(financialCategory) || (values.annual[financialCategory] && values.annual[financialCategory].value === null) || (values.annual[financialCategory] && values.annual[financialCategory].value === undefined)) {
                        totalFieldEmpty += 1;
                    }
                });

                // extract financial category
                convertAnnualToArray.forEach((financialCategory: string) => {     

                    if (totalFieldEmpty >= 5) {
                        errors.annual[financialCategory].value = '1 field is required';
                    // tslint:disable-next-line: max-line-length
                    } else if (values.annual[financialCategory] && values.annual[financialCategory].value > 24 && (financialCategory === 'billableHoursPerDay' || financialCategory === 'nonBillableHoursPerDay')) {
                        errors.annual[financialCategory].value = `max is 24`;
                    }  
                    // check if all field are not empty else return required
                    // if (!(values.annual && Object.keys(values.annual).length)) {
                    //     errors.annual[financialCategory].value = 'required';
                    // tslint:disable-next-line: max-line-length
                    // } else if ((!values.annual.hasOwnProperty(financialCategory) || (values.annual[financialCategory] && values.annual[financialCategory].value === null) || (values.annual[financialCategory] && values.annual[financialCategory].value === undefined))) {
                    //     errors.annual[financialCategory].value = 'required';
                    // tslint:disable-next-line: max-line-length
                    // } else if (values.annual[financialCategory] && values.annual[financialCategory].value > 24 && (financialCategory === 'billableHoursPerDay' || financialCategory === 'nonBillableHoursPerDay')) {
                    //     errors.annual[financialCategory].value = `max is 24`;
                    // }

                });
            }

        } else {

            /*
            * diabled validation for monthly for now
            */
            
            // remove annual and focus on monthly only
            const monthsObject = _.omit(remainingProperty, 'annual');

            // conver object to array
            const monthsObjectConvertToArray = Object.keys(monthsObject);

            monthsObjectConvertToArray.forEach((financialCategory: string) => {     
                if (values.annual[financialCategory] && values.annual[financialCategory].value > 24 && (financialCategory === 'billableHoursPerDay' || financialCategory === 'nonBillableHoursPerDay')) {
                    errors.annual[financialCategory].value = `max is 24`;
                }
            });

            monthsObjectConvertToArray.forEach((month: string) => {
                const convertMonthToArray = Object.keys(remainingProperty[month]);

                // extract financial category
                convertMonthToArray.forEach((financialCategory: string) => {
                    if (values[month][financialCategory] && values[month][financialCategory].value > 24 && (financialCategory === 'billableHoursPerDay' || financialCategory === 'nonBillableHoursPerDay')) {
                        errors[month][financialCategory].value = `max is 24`;
                    }
                });
            });

        }

        let totalRequired = 0;
        const convertErrorsToArray = Object.keys(remainingProperty);

        // check if there is a required field left in order to call the onSubmit function
        if (convertErrorsToArray.length) {
            convertErrorsToArray.forEach((topLevelField: string) => {
                const tempTopLevelField = errors[topLevelField];

                const secondLevelFields = Object.keys(tempTopLevelField);

                if (secondLevelFields.length) {
                    secondLevelFields.forEach((secondLevelField: string) => {
                        const tempSecondLevelField = errors[topLevelField][secondLevelField].value;
                        if (tempSecondLevelField.length > 0) {
                            totalRequired += 1;
                        }
                    });
                }
            });
        }

        // indicate that all input are valid
        if (totalRequired === 0) {
            return undefined;
        }

        return errors;
    };

    // tslint:disable-next-line: no-any
    const onFormValueChanged = (form: FormApi<StaffNameBudgetDialogForm>, changeProps: any) => {
        if (!changeProps.pristine) {
            setIsFormChanged(true);
        }
    };

    const onDiscardChanges = (agree: boolean) => {
        if (agree) {
            if (props.onClose) {
                props.onClose();
            }
        } else {
            setShowDiscard(false);
        }
    };

    // tslint:disable-next-line: no-any
    const required = (value: any) => (value !== undefined && value !== null ? undefined : `required`);

    // tslint:disable-next-line: no-any
    // const mustBeNumber = (value: any) => (isNaN(value) ? 'Must be a number' : undefined);

    // // tslint:disable-next-line: no-any
    // const composeValidators = (...validators: any[]) => (value: any) => validators.reduce((error, validator) => error || validator(value), undefined);

    // tslint:disable-next-line: no-any
    const RadioComp = (compProps: any) => {

        const { input } = compProps;        
        return (
                <Radio
                    {...input}
                    checkedIcon={<RadioButtonCheckedIcon className={classes.radioIcon} />}
                />
        );
    };

    // tslint:disable-next-line: no-any
    const onFinancialYear = (input: FieldInputProps<any, HTMLElement>, formApi: FormApi<StaffNameBudgetDialogForm>) => (selection: FinancialYearItem, name: string) => {
        if (selection) {

            setGenerateMonthLoading(true);

            input.onChange(selection);

            // starting month
            const startMonth = moment(selection.startDate).toDate();

            // last month from starting month
            const endMonth = moment(selection.startDate).add(11, 'month').toDate();

            const monthRange = moment.range(startMonth, endMonth);
            
            // console.log('tempLastMoth', startMonth, endMonth);

            const months = Array.from(monthRange.by('month'));

            fetchStaffBudgets(props.staffGuid, selection, months, formApi);
        }
    };

    const fetchStaffBudgets = (staffGuid: string | null, selection: FinancialYearItem, months: Moment.Moment[], formApi: FormApi<StaffNameBudgetDialogForm>) => {
        
        const staffBudgetQueryParams: StaffBudgetQueryParams = {
            staffGuid: staffGuid,
            startDate: selection.startDate,
            endDate: selection.endDate,
            financialYearStartDate: selection.startDate
        };

        retrieveStaffDailyBudgetsData(
            staffBudgetQueryParams,
            true,
            (data: StaffBudgetQieryData) => onDataRetrieved(data, selection, months, formApi),
            // tslint:disable-next-line: no-any
            function (reason: any): void {
                showNotification(null, reason, 'error');
            }
        );
    };

    // tslint:disable-next-line: no-any
    const onDataRetrieved = (data: StaffBudgetQieryData, selection: FinancialYearItem, months: Moment.Moment[], formApi: FormApi<any>) => {

        const staffMonthlyBudgetData = data.staffBudget.staffMonthlyBudgets.staffMonthlyBudget ?
                    data.staffBudget.staffMonthlyBudgets.staffMonthlyBudget : null;
        
        const staffWorkingDaysData = data.staffBudget.staffWorkingDays ? data.staffBudget.staffWorkingDays : null;

        let totalFeesRecorded = 0;
        let totalFeesBilled = 0;
        let totalFeesCollected = 0;
        
        let isBillableHoursSame = false;
        let isNonBillableHours = false;

        formApi.setConfig('keepDirtyOnReinitialize', false);

        formApi.batch(() => {

            // save all range base on startdate until 12 months
            formApi.change('dateRange', months);

            if (staffWorkingDaysData && Object.keys(staffWorkingDaysData).length) {
                formApi.change('week', {
                    monday: staffWorkingDaysData.monday,
                    tuesday: staffWorkingDaysData.tuesday,
                    wednesday: staffWorkingDaysData.wednesday,
                    thursday: staffWorkingDaysData.thursday,
                    friday: staffWorkingDaysData.friday,
                    saturday: staffWorkingDaysData.saturday,
                    sunday: staffWorkingDaysData.sunday,
                });
            } 

            const bugetPerMonth: { [key: string]: FinancialCategory }  = {};

            if (staffMonthlyBudgetData && staffMonthlyBudgetData.length) {
                
                isBillableHoursSame = _.every(staffMonthlyBudgetData, ['billableHours', staffMonthlyBudgetData[0].billableHours]);
                isNonBillableHours = _.every(staffMonthlyBudgetData, ['nonBillableHours', staffMonthlyBudgetData[0].nonBillableHours]);

                staffMonthlyBudgetData.forEach((staffMonthlyBudget: StaffMonthlyBudget) => {

                    const monthName = moment(staffMonthlyBudget.month, 'M').format('MMMM').toLowerCase();

                    // console.log('monthName', monthName);

                    bugetPerMonth[monthName] = {
                        monthStartDate: moment(staffMonthlyBudget.monthStartDate).startOf('month').format('YYYY-MM-DD'),
                        billableHoursPerDay: {
                            isChanged: false,
                            // tslint:disable-next-line: radix
                            value: parseFloat(staffMonthlyBudget.billableHours)
                        },
                        nonBillableHoursPerDay: {
                            isChanged: false,
                            // tslint:disable-next-line: radix
                            value: parseFloat(staffMonthlyBudget.nonBillableHours)
                        },
                        feesBilled: {
                            isChanged: false,
                            value: staffMonthlyBudget.feesBilled
                        },
                        feesCollected: {
                            isChanged: false,
                            value: staffMonthlyBudget.feesCollected
                        },
                        feesRecorded: {
                            isChanged: false,
                            value: staffMonthlyBudget.billableFees
                        },
                        realisation: {
                            // tslint:disable-next-line: max-line-length
                            value: staffMonthlyBudget.feesBilled && staffMonthlyBudget.billableFees ? (staffMonthlyBudget.feesBilled / staffMonthlyBudget.billableFees) * 100 > 0 ? (staffMonthlyBudget.feesBilled / staffMonthlyBudget.billableFees) * 100 : 0 : 0,
                            isChanged: false
                        },
                        collectability: {
                            // tslint:disable-next-line: max-line-length
                            value: staffMonthlyBudget.feesCollected && staffMonthlyBudget.feesBilled ? (staffMonthlyBudget.feesCollected / staffMonthlyBudget.feesBilled) * 100 > 0 ? (staffMonthlyBudget.feesCollected / staffMonthlyBudget.feesBilled) * 100 : 0 : 0,
                            isChanged: false
                        }
                    };
                    
                    totalFeesRecorded += staffMonthlyBudget.billableFees ? staffMonthlyBudget.billableFees : 0;
                    totalFeesBilled += staffMonthlyBudget.feesBilled ? staffMonthlyBudget.feesBilled : 0;
                    totalFeesCollected += staffMonthlyBudget.feesCollected ? staffMonthlyBudget.feesCollected : 0;
                    
                });
            }
            
            months.forEach((month: Moment.Moment) => {

                const monthName = moment(month).format('MMMM').toLowerCase();

                const isMonthHaveBudget = Object.keys(bugetPerMonth).includes(monthName);

                if (isMonthHaveBudget) {
                    formApi.change(monthName, {
                        ...bugetPerMonth[monthName]
                    });
                } else {
                    formApi.change(monthName, {
                        monthStartDate: moment(month).startOf('month').format('YYYY-MM-DD'),
                        billableHoursPerDay: undefined,
                        nonBillableHoursPerDay: undefined,
                        feesRecorded: undefined,
                        feesBilled: undefined,
                        feesCollected: undefined,
                        realisation: undefined,
                        collectability: undefined
                    });
                }

            });

            formApi.change('annual', {
                billableHoursPerDay: {
                // tslint:disable-next-line: radix max-line-length
                    value: isBillableHoursSame && staffMonthlyBudgetData && staffMonthlyBudgetData.length === 12 ? staffMonthlyBudgetData[0].billableHours : undefined,
                    isChanged: false
                },
                // tslint:disable-next-line: radix max-line-length
                nonBillableHoursPerDay: {
                    value: isNonBillableHours && staffMonthlyBudgetData && staffMonthlyBudgetData.length === 12 ? staffMonthlyBudgetData[0].nonBillableHours : undefined,
                    isChanged: false
                },
                feesRecorded: {
                    value: totalFeesRecorded ? Math.round(totalFeesRecorded) : undefined,
                    isChanged: false
                },
                feesBilled: {
                    value: totalFeesBilled ? Math.round(totalFeesBilled) : undefined,
                    isChanged: false
                },
                feesCollected: {
                    value: totalFeesCollected ? Math.round(totalFeesCollected) : undefined,
                    isChanged: false
                },
                realisation: {
                    value: totalFeesBilled / totalFeesRecorded * 100,
                    isChanged: false
                },
                collectability: {
                    value: totalFeesCollected / totalFeesBilled * 100,
                    isChanged: false
                }
            });
            
        });

        formApi.setConfig('keepDirtyOnReinitialize', true);

        setGenerateMonthLoading(false);
    };

    // tslint:disable-next-line: no-any max-line-length
    const onChangeAnnualValue = (input: FieldInputProps<any, HTMLElement>, formApi: FormApi<any>, values: StaffNameBudgetDialogForm) => (value: number | undefined) => {
        if (values.financialType === 'monthly') {
            return;
        }

        // code here
        input.onChange(value);

        formApi.setConfig('keepDirtyOnReinitialize', false);

        // get input name
        const field = input.name.split('.')[1];

        formApi.batch(() => {

            values.dateRange.forEach((tempDate: Moment.Moment) => {
                // code here
                const month = moment(tempDate).format('MMMM').toLowerCase();

                // copy billable hours and non billable our to monthly
                formApi.change(`${month}.${field}.value`, value);

                // assigned that field changed by user
                formApi.change(`${month}.${field}.isChanged`, true);
            });

        });

        formApi.setConfig('keepDirtyOnReinitialize', true);

    };

    // tslint:disable-next-line: no-any
    const onChangeMonthlyValue = (input: FieldInputProps<any, HTMLElement>, formApi: FormApi<any>, values: StaffNameBudgetDialogForm) => (value: number | undefined) => {

        if (values.financialType === 'annually') {
            return;
        }

        const isChangedInputFieldName = input.name.replace('value', 'isChanged');

        input.onChange(value);

        formApi.setConfig('keepDirtyOnReinitialize', false);

        formApi.batch(() => {

            formApi.change(isChangedInputFieldName, true);

            // topLevelField are financialType, financialYear, months, dateRange
            const topLevelField = input.name.split('.')[0];

            // financial categories and etc..
            const secondLevelField = input.name.split('.')[1];

            if (values[topLevelField] && Object.keys(values[topLevelField]).length) {
                // getting realisation percentegate
                if (secondLevelField === 'feesRecorded' && value && values[topLevelField].feesBilled) {
                    const realisation = values[topLevelField].feesBilled.value / value;
                    const realisationPercentage = realisation * 100;
                    formApi.change(`${topLevelField}.realisation.value`, realisationPercentage);
                    // formApi.change('annual.feesRecorded', 500);
                }

                // getting realisation percentegate
                if (secondLevelField === 'feesBilled' && value && values[topLevelField].feesRecorded) {
                    const realisation = value / values[topLevelField].feesRecorded.value;
                    const realisationPercentage = realisation * 100;
                    formApi.change(`${topLevelField}.realisation.value`, realisationPercentage);
                }

                // getting collectability percentegate
                if (secondLevelField === 'feesBilled' && value && values[topLevelField].feesCollected) {
                    const collectability = values[topLevelField].feesCollected.value / value;
                    const collectabilityPercentage = collectability * 100;
                    formApi.change(`${topLevelField}.collectability.value`, collectabilityPercentage);
                }

                // getting collectability percentegate
                if (secondLevelField === 'feesCollected' && value && values[topLevelField].feesBilled) {
                    const collectability = value / values[topLevelField].feesBilled.value;
                    const collectabilityPercentage = collectability * 100;
                    formApi.change(`${topLevelField}.collectability.value`, collectabilityPercentage);
                }

                if (secondLevelField === 'feesRecorded' || secondLevelField === 'feesBilled' || secondLevelField === 'feesCollected') {

                    // object that need to remove just toget only the object for months
                    const defaultExclude = ['financialType', 'financialYear', 'week', 'annual', 'dateRange'];

                    // removed unuse property
                    const excludedProperty = _.omit(values, [...defaultExclude, topLevelField]);

                    // convert object values to array
                    const arrayOfValues = Object.values(excludedProperty);

                    let totalFeesRecorded = 0;
                    let totalFeesBilled = 0;
                    let totalFeesCollected = 0;

                    // console.log('arrayOfValues', arrayOfValues);

                    arrayOfValues.forEach((finacialCategory: FinancialCategory) => {
                        const selectedField = _.pick(finacialCategory, secondLevelField);
                        totalFeesRecorded += selectedField.feesRecorded && selectedField.feesRecorded.value ? selectedField.feesRecorded.value : 0;
                        totalFeesBilled += selectedField.feesBilled && selectedField.feesBilled.value ? selectedField.feesBilled.value : 0;
                        totalFeesCollected += selectedField.feesCollected && selectedField.feesCollected.value ? selectedField.feesCollected.value : 0;
                    });

                    if (secondLevelField === 'feesRecorded') {
                        totalFeesRecorded += value ? value : 0;
                        formApi.change('annual.feesRecorded.value', totalFeesRecorded);
                    }
                    if (secondLevelField === 'feesBilled') {                    
                        totalFeesBilled += value ? value : 0;
                        formApi.change('annual.feesBilled.value', totalFeesBilled);
                    }
                    if (secondLevelField === 'feesCollected') {
                        totalFeesCollected += value ? value : 0;
                        formApi.change('annual.feesCollected.value', totalFeesCollected);
                    }
                    // console.log('excludedProperty', excludedProperty);

                }

                // calculate annual for billable hours per day and non billable hours per day
                if (secondLevelField === 'billableHoursPerDay' || secondLevelField === 'nonBillableHoursPerDay') {

                    // object that need to remove just toget only the object for months
                    const defaultExclude = ['financialType', 'financialYear', 'week', 'annual', 'dateRange'];

                    // removed unuse property
                    const excludedProperty = _.omit(values, [...defaultExclude, topLevelField]);

                    // convert object values to array
                    const arrayOfValues = Object.values(excludedProperty);

                    // check if all value in array by financial category is the same or not
                    const isSame = _.every(arrayOfValues, [secondLevelField, value]);

                    // assigned value ethier blank or the same value from months
                    formApi.change(`annual.${secondLevelField}`, isSame ? value : undefined);

                }
            }
            
        });

        formApi.setConfig('keepDirtyOnReinitialize', true);

    };

    // tslint:disable-next-line: no-any max-line-length
    const onChangeAnnualFeesCollected = (input: FieldInputProps<any, HTMLElement>, formApi: FormApi<any>, values: StaffNameBudgetDialogForm) => (value: number | null) => {
        if (values.financialType === 'monthly') {
            return;
        }

        // code here
        input.onChange(value);

        formApi.setConfig('keepDirtyOnReinitialize', false);

        formApi.batch(() => {

            if (values.annual && Object.keys(values.annual).length) {
                let tempCollectabilityPercentage: number | undefined = undefined;
                let monthlyCollectabilityPercentage: number | undefined = undefined;

                const feesCollectedByMonth = value ? value / 12 : undefined;

                if (value && values.annual.feesBilled ) {
                    tempCollectabilityPercentage = (value / values.annual.feesBilled.value) * 100;
                    monthlyCollectabilityPercentage = ( (value / 12) / (values.annual.feesBilled.value / 12)) * 100;
                }

                formApi.change('annual.collectability', tempCollectabilityPercentage);

                values.dateRange.forEach((tempDate: Moment.Moment) => {

                    // get the name of the month
                    const month = moment(tempDate).format('MMMM').toLowerCase();

                    formApi.change(`${month}.feesCollected.value`, feesCollectedByMonth);
                    formApi.change(`${month}.feesCollected.isChanged`, true);
                    formApi.change(`${month}.collectability.value`, monthlyCollectabilityPercentage);

                });
            }

        });

        formApi.setConfig('keepDirtyOnReinitialize', true);

    };

    // tslint:disable-next-line: no-any max-line-length
    const onChangeAnnualFeesBilled = (input: FieldInputProps<any, HTMLElement>, formApi: FormApi<any>, values: StaffNameBudgetDialogForm) => (value: number | null) => {
        if (values.financialType === 'monthly') {
            return;
        }

        // code here
        input.onChange(value);

        formApi.setConfig('keepDirtyOnReinitialize', false);

        formApi.batch(() => {

            if (values.annual && Object.keys(values.annual).length) {
                let tempCollectabilityPercentage: number | undefined = undefined;
                let monthlyCollectabilityPercentage: number | undefined = undefined;

                let tempRealisationPercentage: number | undefined = undefined;
                let monthlyRealisationPercentage: number | undefined = undefined;

                const feesBilledByMonth = value ? value / 12 : undefined;

                if (value && values.annual.feesCollected ) {
                    tempCollectabilityPercentage = (values.annual.feesCollected.value / value) * 100;
                    monthlyCollectabilityPercentage = ((values.annual.feesCollected.value / 12) / (value / 12)) * 100;
                }

                if (value && values.annual.feesRecorded ) {
                    tempRealisationPercentage = (value / values.annual.feesRecorded.value) * 100;
                    monthlyRealisationPercentage = ((value / 12) / (values.annual.feesRecorded.value / 12)) * 100;
                }

                formApi.change('annual.collectability.value', tempCollectabilityPercentage);
                formApi.change('annual.realisation.value', tempRealisationPercentage);

                values.dateRange.forEach((tempDate: Moment.Moment) => {

                    // get the name of the month
                    const month = moment(tempDate).format('MMMM').toLowerCase();

                    formApi.change(`${month}.feesBilled.value`, feesBilledByMonth);
                    formApi.change(`${month}.feesBilled.isChanged`, true);
                    formApi.change(`${month}.collectability.value`, monthlyCollectabilityPercentage);
                    formApi.change(`${month}.realisation.value`, monthlyRealisationPercentage);

                });
            }

        });

        formApi.setConfig('keepDirtyOnReinitialize', true);

    };

    // tslint:disable-next-line: no-any max-line-length
    const onChangeAnnualFeesRecorded = (input: FieldInputProps<any, HTMLElement>, formApi: FormApi<any>, values: StaffNameBudgetDialogForm) => (value: number | null) => {
        if (values.financialType === 'monthly') {
            return;
        }

        input.onChange(value);

        formApi.setConfig('keepDirtyOnReinitialize', false);

        formApi.batch(() => {

            if (values.annual && Object.keys(values.annual).length) {
                let tempRealisationPercentage: number | undefined = undefined;
                let monthlyRealisationPercentage: number | undefined = undefined;

                const feesRecordedByMonth = value ? value / 12 : undefined;

                if (value && values.annual.feesBilled ) {
                    tempRealisationPercentage = (values.annual.feesBilled.value / value) * 100;
                    monthlyRealisationPercentage = ((values.annual.feesBilled.value / 12) / (value / 12)) * 100;
                }

                formApi.change('annual.realisation.value', tempRealisationPercentage);

                values.dateRange.forEach((tempDate: Moment.Moment) => {

                    // get the name of the month
                    const month = moment(tempDate).format('MMMM').toLowerCase();

                    formApi.change(`${month}.feesRecorded.value`, feesRecordedByMonth);
                    formApi.change(`${month}.feesRecorded.isChanged`, true);
                    formApi.change(`${month}.realisation.value`, monthlyRealisationPercentage);

                });
            }
        });

        formApi.setConfig('keepDirtyOnReinitialize', true);
    };

    // tslint:disable-next-line: no-any
    const onGetPercentage = (value: any, suffix?: string) => {
        if (Object.keys(value).length && value && value.hasOwnProperty('realisation') && value.realisation && value.realisation.value && suffix?.toLocaleLowerCase() === 'realisation') {
            return `${formatNumber(value.realisation.value)}% ${suffix}`;
        }
        if (Object.keys(value).length && value && value.hasOwnProperty('collectability') && value.collectability && value.collectability.value && suffix?.toLocaleLowerCase() === 'collectability') {
            return `${formatNumber(value.collectability.value)}% ${suffix}`;
        }
        return '';
    };

    // tslint:disable-next-line: max-line-length no-any
    const onCheckboxChange = (input: FieldInputProps<any, HTMLElement>, formApi: FormApi<any>, values: StaffNameBudgetDialogForm) => (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {

        input.onChange(checked);

        const defaultExclude = ['financialType', 'financialYear', 'week', 'dateRange'];

        // removed unuse property
        const excludedProperty = _.omit(values, [...defaultExclude]);

        const monthAndAnnualKeys = Object.keys(excludedProperty);
        
        formApi.setConfig('keepDirtyOnReinitialize', false);

        formApi.batch(() => {
            
            monthAndAnnualKeys.forEach((monthAndAnnual: string) => {
                const financialCategoryKeys = Object.keys(values[monthAndAnnual]);

                financialCategoryKeys.forEach((financialCategory: string) => {
                    const field = values[monthAndAnnual][financialCategory];
                    // check if field has value then apply the ischange to true
                    if (field && Object.keys(field).length && field.hasOwnProperty('value') && (field.value !== null || field.value !== undefined)) {
                        formApi.change(`${monthAndAnnual}.${financialCategory}.isChanged`, true);
                    }
                });
                // if (values[finacialCategory].value !== undefined || values[finacialCategory].value !== null)
            });
        });

        formApi.setConfig('keepDirtyOnReinitialize', true);

    };

    const actionButton = (
        // tslint:disable-next-line: no-any
        form: FormApi<StaffNameBudgetDialogForm>,
        submitting: boolean,
        pristine: boolean,
        // tslint:disable-next-line: no-any
        values: AnyObject
    ) => {
        return (
            <div className={classes.buttonContainer}>
                {/* <RenderCount /> */}
                <div>
                    <Button
                        color="primary"
                        type="submit"
                        className={classes.button}
                        onClick={() => {
                            // code here..
                        }}
                        disabled={submitting || pristine || props.enableBudgetSync}
                    >
                        Save
                    </Button>
                </div>
                <div>
                    <Button
                        id="resetButton"
                        type="button"
                        onClick={onReset}
                        disabled={submitting || pristine || props.enableBudgetSync}
                        className={classes.hidden}
                    >
                        Reset
                    </Button>
                    <Button
                        color="primary"
                        type="button"
                        className={classes.button}
                        onClick={onClose}
                    >
                        Cancel
                    </Button>
                </div>
            </div>
        );
    };

    const financialCategoryComponent = (formApi: FormApi<StaffNameBudgetDialogForm>) => {
        return (
            <Grid container={true} spacing={1}>
                <Grid item={true} xs={12} sm={12} md={12} lg={12}>
                    <Grid container={true} spacing={1}>
                        <Grid item={true} xs={12} sm={12} md={6} lg={6}>
                            <Field
                                name="financialYear"
                                validate={required}
                                required={true}
                                helperText={'Priority is required'}
                                // validate={form.getFieldState('priority')?.value !== undefined ? required : undefined}
                            >
                                {({ input, meta }) => (
                                    <FinancialYearAutoComplete
                                        {...input}
                                        label="Financial Year"
                                        value={input.value ? input.value : null}
                                        required={true}
                                        className={`${classes.textField}`}
                                        error={meta.error && meta.touched}
                                            // tslint:disable-next-line: jsx-alignment
                                        onSelection={onFinancialYear(input, formApi)}
                                        helperText={
                                            meta.error &&
                                            meta.touched
                                                ? 'Role is required'
                                                : ''
                                        }
                                        disablePortal={false}
                                    />
                                )}
                            </Field>
                        </Grid>
                    </Grid>
                </Grid>
                
                <Grid item={true} xs={12} sm={12} md={12} lg={12}>
                    <FormControl component="fieldset">
                        {/* <FormLabel component="legend">Enter</FormLabel> */}
                        <RadioGroup
                            // defaultValue={state.financialType}
                            className={classes.radioGroup}
                        >
                            <FormControlLabel 
                                control={
                                    <Field 
                                        subscription={{touched: true, error: true, value: true}}
                                        name="financialType" 
                                        component={RadioComp}
                                        type="radio"
                                    />
                                }
                                value="annually"
                                label="Annually" 
                            />
                            <FormControlLabel 
                                control={
                                    <Field 
                                        subscription={{touched: true, error: true, value: true}}
                                        name="financialType" 
                                        component={RadioComp}
                                        type="radio"
                                    />
                                }
                                value="monthly"
                                label="Monthly" 
                            />
                        </RadioGroup>
                    </FormControl>
                </Grid>
            </Grid>
        );
    };

    const weekdaysComponent = (formApi: FormApi<StaffNameBudgetDialogForm>, values: StaffNameBudgetDialogForm) => {
        return (
            <div className={classes.weekWrapper}>
                <Typography>Working Days</Typography>
                <FormControl component="fieldset">
                    <FormGroup aria-label="position" row={true}>
                        <Field
                            name="week.sunday"
                            subscription={{ touched: true, error: true, value: true }}
                        >
                            {({ input, meta }) => (
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            {...input}
                                            color="primary"
                                            // onChange={(event)=> input.onChange(event.target.checked)}
                                            checked={input.value}
                                        />
                                    }
                                    labelPlacement="bottom"
                                    label="Sun"
                                />
                            )}
                        </Field>
                        <Field
                            name="week.monday"
                            subscription={{ touched: true, error: true, value: true }}
                        >
                            {({ input, meta }) => (
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            {...input}
                                            color="primary"
                                            // onChange={(event)=> input.onChange(event.target.checked)}
                                            checked={input.value}
                                            onChange={onCheckboxChange(input, formApi, values)}
                                        />
                                    }
                                    labelPlacement="bottom"
                                    label="Mon"
                                />
                            )}
                        </Field>
                        <Field
                            name="week.tuesday"
                            subscription={{ touched: true, error: true, value: true }}
                        >
                            {({ input, meta }) => (
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            {...input}
                                            color="primary"
                                            // onChange={(event)=> input.onChange(event.target.checked)}
                                            checked={input.value}
                                            onChange={onCheckboxChange(input, formApi, values)}
                                        />
                                    }
                                    labelPlacement="bottom"
                                    label="Tue"
                                />
                            )}
                        </Field>
                        <Field
                            name="week.wednesday"
                            subscription={{ touched: true, error: true, value: true }}
                        >
                            {({ input, meta }) => (
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            {...input}
                                            color="primary"
                                            // onChange={(event)=> input.onChange(event.target.checked)}
                                            checked={input.value}
                                            onChange={onCheckboxChange(input, formApi, values)}
                                        />
                                    }
                                    labelPlacement="bottom"
                                    label="Wed"
                                />
                            )}
                        </Field>
                        <Field
                            name="week.thursday"
                            subscription={{ touched: true, error: true, value: true }}
                        >
                            {({ input, meta }) => (
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            {...input}
                                            color="primary"
                                            // onChange={(event)=> input.onChange(event.target.checked)}
                                            checked={input.value}
                                            onChange={onCheckboxChange(input, formApi, values)}
                                        />
                                    }
                                    labelPlacement="bottom"
                                    label="Thu"
                                />
                            )}
                        </Field>
                        <Field
                            name="week.friday"
                            subscription={{ touched: true, error: true, value: true }}
                        >
                            {({ input, meta }) => (
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            {...input}
                                            color="primary"
                                            // onChange={(event)=> input.onChange(event.target.checked)}
                                            checked={input.value}
                                            onChange={onCheckboxChange(input, formApi, values)}
                                        />
                                    }
                                    labelPlacement="bottom"
                                    label="Fri"
                                />
                            )}
                        </Field>
                        <Field
                            name="week.saturday"
                            subscription={{ touched: true, error: true, value: true }}
                        >
                            {({ input, meta }) => (
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            {...input}
                                            color="primary"
                                            // onChange={(event)=> input.onChange(event.target.checked)}
                                            checked={input.value}
                                            onChange={onCheckboxChange(input, formApi, values)}
                                        />
                                    }
                                    labelPlacement="bottom"
                                    label="Sat"
                                />
                            )}
                        </Field>
                    </FormGroup>
                </FormControl>
            </div>
        );
    };

    const annualComponent = (disabled: boolean, form: FormApi<StaffNameBudgetDialogForm>, values: StaffNameBudgetDialogForm) => {
        return (
            <Grid container={true} spacing={1}>
                <Grid item={true} xs={12} sm={12} md={2} lg={2}>
                    <Typography>Annual Amount</Typography>
                </Grid>
                <Grid item={true} xs={12} sm={12} md={2} lg={2} className={classes.textRight}>
                    <Field
                        name="annual.feesRecorded.value"
                        // validate={values.financialType === 'annually' ? composeValidators(required, mustBeNumber) : undefined}
                    >
                        {(fieldProps) => (
                            <CurrencyInput2 
                                {...fieldProps}
                                disabled={disabled}
                                fixedDecimalScale={true}
                                size="small"
                                className={classes.amountField}
                                label="Fees Recorded"
                                prefix={props.currencySymbol}
                                InputLabelProps={{ shrink: true }}
                                onChangeValue={onChangeAnnualFeesRecorded(fieldProps.input, form, values)}
                            />
                        )}
                    </Field>
                </Grid>
                <Grid item={true} xs={12} sm={12} md={2} lg={2} className={classes.textRight}>
                    <Field
                        name="annual.feesBilled.value"
                        // validate={values.financialType === 'annually' ? composeValidators(required, mustBeNumber) : undefined}
                    >
                        {(fieldProps) => (
                            <CurrencyInput2 
                                {...fieldProps}
                                disabled={disabled}
                                fixedDecimalScale={true}
                                size="small"
                                className={classes.amountField}
                                label="Fees Billed"
                                prefix={props.currencySymbol}
                                InputLabelProps={{ shrink: true }}
                                onChangeValue={onChangeAnnualFeesBilled(fieldProps.input, form, values)}
                                helperText={
                                    fieldProps.meta.error &&
                                    fieldProps.meta.touched
                                        ? 'Required'
                                        : `${onGetPercentage(values.annual, 'Realisation')}`
                                }
                            />
                        )}
                    </Field>
                </Grid>
                {/* <Grid item={true} xs={12} sm={12} md={2} lg={2} className={classes.textRight}>
                    <Field
                        name="annual.realisation.value"
                        // validate={values.financialType === 'annually' ? composeValidators(required, mustBeNumber) : undefined}
                    >
                        {(fieldProps) => (
                            <CurrencyInput2 
                                {...fieldProps}
                                disabled={disabled}
                                size="small"
                                className={classes.amountField}
                                label="Realisation"
                                suffix={'%'}
                                InputLabelProps={{ shrink: true }}
                                InputProps={{
                                    readOnly: true
                                }}
                                onChangeValue={onChangeAnnualValue(fieldProps.input, form, values)}
                            />
                        )}
                    </Field>
                </Grid> */}
                <Grid item={true} xs={12} sm={12} md={2} lg={2} className={classes.textRight}>
                    <Field
                        name="annual.feesCollected.value"
                        // validate={values.financialType === 'annually' ? composeValidators(required, mustBeNumber) : undefined}
                    >
                        {(fieldProps) => (
                            <CurrencyInput2 
                                {...fieldProps}
                                disabled={disabled}
                                fixedDecimalScale={true}
                                size="small"
                                className={classes.amountField}
                                label="Fees Collected"
                                prefix={props.currencySymbol}
                                InputLabelProps={{ shrink: true }}
                                onChangeValue={onChangeAnnualFeesCollected(fieldProps.input, form, values)}
                                helperText={
                                    fieldProps.meta.error &&
                                    fieldProps.meta.touched
                                        ? 'Required'
                                        : `${onGetPercentage(values.annual, 'Collectability')}`
                                }
                            />
                        )}
                    </Field>
                </Grid>
                {/* <Grid item={true} xs={12} sm={12} md={2} lg={2} className={classes.textRight}>
                    <Field
                        name="annual.collectability.value"
                        // validate={values.financialType === 'annually' ? composeValidators(required, mustBeNumber) : undefined}
                    >
                        {(fieldProps) => (
                            <CurrencyInput2 
                                {...fieldProps}
                                disabled={disabled}
                                size="small"
                                className={classes.amountField}
                                label="Collectability"
                                suffix={'%'}
                                InputLabelProps={{ shrink: true }}
                                InputProps={{
                                    readOnly: true
                                }}
                                onChangeValue={onChangeAnnualValue(fieldProps.input, form, values)}
                            />
                        )}
                    </Field>
                </Grid> */}
                <Grid item={true} xs={12} sm={12} md={2} lg={2} className={classes.textRight}>
                    <Field
                        name="annual.billableHoursPerDay.value"
                        // validate={values.financialType === 'annually' ? composeValidators(required, mustBeNumber) : undefined}
                    >
                        {(fieldProps) => (
                            <CurrencyInput2 
                                {...fieldProps}
                                decimalScale={1}
                                fixedDecimalScale={false}
                                disabled={disabled}
                                size="small"
                                className={classes.amountField}
                                label="Billable hours per day"
                                InputLabelProps={{ shrink: true }}
                                onChangeValue={onChangeAnnualValue(fieldProps.input, form, values)}
                            />
                        )}
                    </Field>
                </Grid>
                <Grid item={true} xs={12} sm={12} md={2} lg={2} className={classes.textRight}>
                    <Field
                        name="annual.nonBillableHoursPerDay.value"
                        // validate={values.financialType === 'annually' ? composeValidators(required, mustBeNumber) : undefined}
                    >
                        {(fieldProps) => (
                            <CurrencyInput2 
                                {...fieldProps}
                                decimalScale={1}
                                fixedDecimalScale={false}
                                disabled={disabled}
                                size="small"
                                className={classes.amountField}
                                label="Non Billable hours per day"
                                InputLabelProps={{ shrink: true }}
                                onChangeValue={onChangeAnnualValue(fieldProps.input, form, values)}
                            />
                        )}
                    </Field>
                </Grid>
            </Grid>
        );
    };

    const monthlyComponent = (disabled: boolean, form: FormApi<StaffNameBudgetDialogForm>, values: StaffNameBudgetDialogForm) => {
        return (
            <>
                {values.dateRange.map((tempDate: Moment.Moment) => {

                    const month = moment(tempDate).format('MMMM').toLowerCase();

                    return (
                        <Grid item={true} xs={12} sm={12} md={12} lg={12} key={month}>
                            <Grid container={true} spacing={1}>
                                <Grid item={true} xs={12} sm={12} md={2} lg={2}>
                                    <Typography style={{ textTransform: 'capitalize' }}>{month}</Typography>
                                </Grid>
                                <Grid item={true} xs={12} sm={12} md={2} lg={2} className={classes.textRight}>
                                    <Field
                                        name={`${month}.feesRecorded.value`}
                                        // validate={values.financialType === 'monthly' ? composeValidators(required, mustBeNumber) : undefined}
                                        key={month}
                                    >
                                        {(fieldProps) => (
                                            <CurrencyInput2 
                                                {...fieldProps}
                                                disabled={disabled}
                                                fixedDecimalScale={true}
                                                size="small"
                                                className={classes.amountField}
                                                // label="Fees Recorded"
                                                prefix={props.currencySymbol}
                                                InputLabelProps={{ shrink: true }}
                                                onChangeValue={onChangeMonthlyValue(fieldProps.input, form, values)}
                                            />
                                        )}
                                    </Field>
                                </Grid>
                                <Grid item={true} xs={12} sm={12} md={2} lg={2} className={classes.textRight}>
                                    <Field
                                        name={`${month}.feesBilled.value`}
                                        // validate={values.financialType === 'monthly' ? composeValidators(required, mustBeNumber) : undefined}
                                        key={month}
                                    >
                                        {(fieldProps) => (
                                            <CurrencyInput2 
                                                {...fieldProps}
                                                disabled={disabled}
                                                fixedDecimalScale={true}
                                                size="small"
                                                className={classes.amountField}
                                                // label="Fees Billed"
                                                prefix={props.currencySymbol}
                                                InputLabelProps={{ shrink: true }}
                                                onChangeValue={onChangeMonthlyValue(fieldProps.input, form, values)}
                                                helperText={
                                                    fieldProps.meta.error &&
                                                    fieldProps.meta.touched
                                                        ? 'Required'
                                                        : `${onGetPercentage(values[month], 'Realisation')}`
                                                }
                                            />
                                        )}
                                    </Field>
                                </Grid>
                                {/* <Grid item={true} xs={12} sm={12} md={2} lg={2} className={classes.textRight}>
                                    <Field
                                        name={`${month}.realisation.value`}
                                        // validate={values.financialType === 'monthly' ? composeValidators(required, mustBeNumber) : undefined}
                                    >
                                        {(fieldProps) => (
                                            <CurrencyInput2 
                                                {...fieldProps}
                                                disabled={disabled}
                                                size="small"
                                                className={classes.amountField}
                                                label="Realisation"
                                                suffix={'%'}
                                                InputLabelProps={{ shrink: true }}
                                                InputProps={{
                                                    readOnly: true
                                                }}
                                                onChangeValue={onChangeMonthlyValue(fieldProps.input, form, values)}
                                            />
                                        )}
                                    </Field>
                                </Grid> */}
                                <Grid item={true} xs={12} sm={12} md={2} lg={2} className={classes.textRight}>
                                    <Field
                                        name={`${month}.feesCollected.value`}
                                        // validate={values.financialType === 'monthly' ? composeValidators(required, mustBeNumber) : undefined}
                                        key={month}
                                    >
                                        {(fieldProps) => (
                                            <CurrencyInput2 
                                                {...fieldProps}
                                                disabled={disabled}
                                                fixedDecimalScale={true}
                                                size="small"
                                                className={classes.amountField}
                                                // label="Fees Collected"
                                                prefix={props.currencySymbol}
                                                InputLabelProps={{ shrink: true }}
                                                onChangeValue={onChangeMonthlyValue(fieldProps.input, form, values)}
                                                helperText={
                                                    fieldProps.meta.error &&
                                                    fieldProps.meta.touched
                                                        ? 'Required'
                                                        : `${onGetPercentage(values[month], 'Collectability')}`
                                                }
                                            />
                                        )}
                                    </Field>
                                </Grid>
                                {/* <Grid item={true} xs={12} sm={12} md={2} lg={2} className={classes.textRight}>
                                    <Field
                                        name={`${month}.collectability.value`}
                                        // validate={values.financialType === 'monthly' ? composeValidators(required, mustBeNumber) : undefined}
                                    >
                                        {(fieldProps) => (
                                            <CurrencyInput2 
                                                {...fieldProps}
                                                disabled={disabled}
                                                size="small"
                                                className={classes.amountField}
                                                label="Collectability"
                                                suffix={'%'}
                                                InputLabelProps={{ shrink: true }}
                                                InputProps={{
                                                    readOnly: true
                                                }}
                                                onChangeValue={onChangeMonthlyValue(fieldProps.input, form, values)}
                                            />
                                        )}
                                    </Field>
                                </Grid> */}
                                <Grid item={true} xs={12} sm={12} md={2} lg={2} className={classes.textRight}>
                                    <Field
                                        name={`${month}.billableHoursPerDay.value`}
                                        // validate={values.financialType === 'monthly' ? composeValidators(required, mustBeNumber) : undefined}
                                        key={month}
                                    >
                                        {(fieldProps) => (
                                            <CurrencyInput2 
                                                {...fieldProps}
                                                decimalScale={1}
                                                fixedDecimalScale={false}
                                                disabled={disabled}
                                                size="small"
                                                className={classes.amountField}
                                                // label={'Billable hours per day'}
                                                InputLabelProps={{ shrink: true }}
                                                onChangeValue={onChangeMonthlyValue(fieldProps.input, form, values)}
                                            />
                                        )}
                                    </Field>
                                </Grid>
                                <Grid item={true} xs={12} sm={12} md={2} lg={2} className={classes.textRight}>
                                    <Field
                                        name={`${month}.nonBillableHoursPerDay.value`}
                                        // validate={values.financialType === 'monthly' ? composeValidators(required, mustBeNumber) : undefined}
                                        key={month}
                                    >
                                        {(fieldProps) => (
                                            <CurrencyInput2 
                                                {...fieldProps}
                                                decimalScale={1}
                                                fixedDecimalScale={false}
                                                disabled={disabled}
                                                size="small"
                                                className={classes.amountField}
                                                // label="Non Billable hours per day"
                                                InputLabelProps={{ shrink: true }}
                                                onChangeValue={onChangeMonthlyValue(fieldProps.input, form, values)}
                                            />
                                        )}
                                    </Field>
                                </Grid>
                            </Grid>
                        </Grid>
                    );
                })}
            </>
        );
    };

    return (
        <Dialog 
            open={props.open} 
            onClose={onClose} 
            className={classes.root}
            maxWidth="md"
            fullWidth={true}
        >
            {onSubmitLoading && (
                <LoadingDialog 
                    open={onSubmitLoading} 
                    maxWidth="xs" 
                    title="Generating Budgets"
                    secondaryTitle=""
                    displayCloseButton={false}
                    // onClose={() => setOnSubmitLoading(false)}
                />
            )}

            <DialogBox
                title="Alert"
                // tslint:disable-next-line:max-line-length
                content={`Are you sure you want to close the form?`}
                show={showDiscard ? showDiscard : false}
                isAgree={onDiscardChanges}
                disAgreeLabel={'No'}
                agreeLabel={'Yes'}
            />
            <DialogTitle>{props.staffName}</DialogTitle>
            <Form
                onSubmit={onSubmit}                
                initialValues={formState}
                subscription={{ submitting: true, pristine: true }}
                initialValuesEqual={() => true}
                keepDirtyOnReinitialize={true}
                validate={onValidate}
                // debug={(values) => {
                //     // tslint:disable-next-line: no-console
                //     console.log('Debug Values', values);
                // }}
                render={({handleSubmit, form, submitting, pristine, values}) => (
                    <form onSubmit={handleSubmit} id="staffBudgetForm">

                        <FormSpy 
                            subscription={{ pristine: true }}
                            // tslint:disable-next-line: no-shadowed-variable
                            onChange={props => {
                                onFormValueChanged(form, props);
                            }}
                        />
                                
                        <DialogContent dividers={true}>
                            <Grid container={true} spacing={1}>
                                <Grid item={true} xs={12} sm={12} md={12} lg={12}>
                                    <Grid container={true} spacing={1}>
                                        <Grid item={true} xs={12} sm={12} md={5} lg={5}>
                                            {financialCategoryComponent(form)}
                                        </Grid>
                                        <FormSpy subscription={{values: true, validating: true}}>
                                            {/* tslint:disable-next-line: no-shadowed-variable */}
                                            {({ values }: { values: StaffNameBudgetDialogForm }) => (
                                                <Grid item={true} xs={12} sm={12} md={7} lg={7}>
                                                    {weekdaysComponent(form, values)}
                                                </Grid>
                                            )}
                                        </FormSpy>
                                    </Grid>
                                </Grid>
                                <Divider />
                                <Grid item={true} xs={12} sm={12} md={12} lg={12}>
                                    <FormSpy subscription={{values: true, validating: true}}>
                                        {/* tslint:disable-next-line: no-shadowed-variable */}
                                        {({ values }: { values: StaffNameBudgetDialogForm }) => (
                                            <Grid container={true} spacing={1}>
                                                {generateMonthLoading ? (
                                                    <Grid item={true} xs={12} sm={12} md={12} lg={12}>
                                                        <Paper elevation={3} className={classes.paper}>
                                                            <div className={classes.loadingWrapper}>
                                                                <CircularProgress />
                                                                <Typography className={classes.loadingTitle}>Retrieving budgets...</Typography>
                                                            </div>
                                                        </Paper>
                                                    </Grid>
                                                ) : (
                                                    <>
                                                        <Grid item={true} xs={12} sm={12} md={12} lg={12}>
                                                            <Paper elevation={3} className={classes.paper}>
                                                                {annualComponent(values.financialType === 'monthly', form, values)}
                                                            </Paper>
                                                        </Grid>
                                                        <Grid item={true} xs={12} sm={12} md={12} lg={12}>
                                                            <Paper elevation={3} className={classes.paper}>
                                                                <Grid container={true} spacing={1}>
                                                                    <Grid item={true} xs={12} sm={12} md={12} lg={12}>
                                                                        <Grid container={true} spacing={1}>
                                                                            <Grid item={true} xs={12} sm={12} md={2} lg={2} />
                                                                            <Grid item={true} xs={12} sm={12} md={2} lg={2} className={classes.textRight}>Fees Recorded</Grid>
                                                                            <Grid item={true} xs={12} sm={12} md={2} lg={2} className={classes.textRight}>Fees Billed</Grid>
                                                                            <Grid item={true} xs={12} sm={12} md={2} lg={2} className={classes.textRight}>Fees Collected</Grid>
                                                                            <Grid item={true} xs={12} sm={12} md={2} lg={2} className={classes.textRight}>
                                                                                Billable hours per day
                                                                            </Grid>
                                                                            <Grid item={true} xs={12} sm={12} md={2} lg={2} className={classes.textRight}>
                                                                                Non Billable hours per day
                                                                            </Grid>
                                                                        </Grid>
                                                                    </Grid>
                                                                    {monthlyComponent(values.financialType === 'annually', form, values)}
                                                                </Grid>
                                                            </Paper>
                                                        </Grid>
                                                    </>
                                                )}
                                            </Grid>
                                        )}
                                    </FormSpy>
                                </Grid>
                            </Grid>
                        {/* {printJson(values)} */}
                        </DialogContent>
                        <DialogActions>
                            {actionButton(form, submitting, pristine, values)}
                        </DialogActions>
                    </form>
                )} 
            />
        </Dialog>
    );
};

// tslint:disable-next-line: no-any
export function printJson(values: any) {
    if (values) {
        return (
            <>
                <pre>
                    {JSON.stringify(values, undefined, 2)}
                </pre>
            </>
        );
    } else {
        return (
            <>
                <FormSpy subscription={{ values: true }}>
                    {/* tslint:disable-next-line: no-shadowed-variable */}
                    {({ values }) => (
                        <pre>
                            {JSON.stringify(values, undefined, 2)}
                            {/* <RenderCount /> */}
                        </pre>
                    )}
                </FormSpy>
            </>
        );
    }
}

export interface StaffMonthlyBudgetInputParams {
    staffGuid: string | null;
    staffMonthlyBudgetInput: {
        staffMonthlyBudgetsInput: StaffMonthlyBudgetsInput[];
    };
    staffWorkingDaysInput: StaffWorkingDaysInput;
}

export interface StaffMonthlyBudgetsInput {
    monthStartDate?: string;
    chargeableHours?: number;
    nonChargeableHours?: number;
    feesRecorded?: number;
    feesBilled?: number;
    feesCollected?: number;
}

export interface StaffWorkingDaysInput {
    financialYearStartDate: string | null;
    sunday: boolean | null;
    monday: boolean | null;
    tuesday: boolean | null;
    wednesday: boolean | null;
    thursday: boolean | null;
    friday: boolean | null;
    saturday: boolean | null;
}

export const UPDATE_STAFF_MONTHLY_BUDGET_MUTATION = gql`
    mutation UpdateStaffMonthlyBudget(
        $staffGuid: String,
        $staffWorkingDaysInput: StaffWorkingDaysInput
        $staffMonthlyBudgetInput : UpdateStaffMonthlyBudgetInput,
    ) {
        updateStaffMonthlyBudget(      
            staffGuid: $staffGuid,
            staffWorkingDaysInput: $staffWorkingDaysInput,
            staffMonthlyBudgetInput: $staffMonthlyBudgetInput
        )
    }
`;

export function retrieveStaffDailyBudgetsData(
    query: StaffBudgetQueryParams,
    refresh: Boolean,
    onSuccess: (data: StaffBudgetQieryData) => void,
    // tslint:disable-next-line:no-any
    onError: (reason: any) => void
) {
    var fetchPolicy: FetchPolicy = refresh === true ? 'network-only' : 'cache-first';
    client.query({
        query: STAFF_MONTHLY_BUDGETS_QUERY,
            variables: {
            staffGuid: query.staffGuid,
            startDate: query.startDate,
            endDate: query.endDate,
            financialYearStartDate: query.financialYearStartDate
        },
        fetchPolicy: fetchPolicy
    })
    // tslint:disable-next-line:no-any
    .then((results: { data: any; }) => {
        onSuccess(results.data);
    })
    // tslint:disable-next-line:no-any
    .catch((reason: any ) => { onError(reason); 
    });
}

interface StaffBudgetQueryParams {
    staffGuid: string | null;
    startDate: Date | null;
    endDate: Date | null;
    financialYearStartDate: Date | null;
}
export interface StaffBudgetQieryData {
    staffBudget: StaffBudget;
}

export interface StaffBudget {
    staffMonthlyBudgets: StaffMonthlyBudgets;
    staffWorkingDays: StaffWorkingDays;
}

export interface StaffMonthlyBudgets {
    recordCount:        number;
    staffMonthlyBudget: StaffMonthlyBudget[];
}

export interface StaffMonthlyBudget {
    id:               number;
    staffId:          number;
    staffGuid:        string;
    monthStartDate:   Date;
    month:            number;
    billableHours:    string;
    nonBillableHours: string;
    feesBilled:       number;
    feesCollected:    number;
    billableFees:     number;
}

export interface StaffWorkingDays {
    id:                     number;
    staffGuid:              string;
    financialYearStartDate: Date;
    monday:                 boolean;
    tuesday:                boolean;
    wednesday:              boolean;
    thursday:               boolean;
    friday:                 boolean;
    saturday:               boolean;
    sunday:                 boolean;
}

export const STAFF_MONTHLY_BUDGETS_QUERY = gql`
    query staffMonthlyBudgets($staffGuid: String, $startDate: DateTime, $endDate: DateTime, $financialYearStartDate: DateTime) {
        staffBudget{
            staffMonthlyBudgets(staffGuid: $staffGuid, startDate: $startDate, endDate: $endDate){
                recordCount,
            		staffMonthlyBudget{
                  id,
                  staffId,
                  staffGuid,
                  monthStartDate,
                  month,
                  billableHours,
                  nonBillableHours,
                  feesBilled,
                  feesCollected,
                  billableFees
                }                        
            }
          	staffWorkingDays(staffGuid: $staffGuid, financialYearStartDate: $financialYearStartDate) {
              id,
              staffGuid
              financialYearStartDate,
              monday,
              tuesday,
              wednesday,
              thursday,
              friday,
              saturday,
              sunday
            }
        }
    }
`;