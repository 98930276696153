// tslint:disable-next-line: max-line-length
import { 
    Button, 
    Checkbox, 
    createStyles, 
    Dialog, 
    DialogActions, 
    DialogContent, 
    DialogTitle, 
    FormControl, 
    FormControlLabel, 
    FormGroup, 
    Grid, 
    makeStyles, 
    Theme 
} from '@material-ui/core';
import { KeyboardDatePicker } from '@material-ui/pickers/DatePicker/DatePicker';
import { FormApi } from 'final-form';
import gql from 'graphql-tag';
import Moment from 'moment';
import { extendMoment } from 'moment-range';
import React, { useEffect, useState } from 'react';
import { AnyObject, Field, Form, FormSpy } from 'react-final-form';
import { client } from '../..';
import { showNotification } from '../../App';
import { CurrencyInput2 } from '../../components/CurrencyInput';
import { RvLoader } from '../../components/Loader';
import DialogBox from '../../Dashboard/Component/DialogBox';
import { UserFeature, UserFeatureEnum } from '../../types/UserFeature';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {},
        loaderWrapper: {
            display: 'flex',
            justifyContent: 'center',
            padding: '20px 0',
        },
        textField: {
            width: '100%',
        },
        buttonContainer: {
            display: 'flex',
            justifyContent: 'flex-end',
            // paddingBottom: '20px'
        },
        button: {
            margin: theme.spacing(1),
            minWidth: '83px',
        },
    })
);

interface StaffDailyBudgetsInput {
    staffDailyBudgetsInput: StaffDailyBudget[];
}
interface StaffDailyBudget {
    staffId: string | null;
    budgetDate: string | null;
    chargeableHours: number | null;
    nonChargeableHours: number | null;
    feesRecorded: number | null;
    feesBilled: number | null;
    feesCollected: number | null;
}

export interface StaffBudgetForm {
    startDate: Date | null;
    endDate: Date | null;
    
    billableHoursPerDay: number | null;
    billableAmountPerDay: number | null;

    nonBillableHoursPerDay: number | null;

    feesBilledPerDay: number | null;
    feesCollectedPerDay: number | null;
}

interface StaffBudgetMaintenanceDailogForm extends StaffBudgetForm {
    week: {
        sunday: boolean;
        monday: boolean;
        tuesday: boolean;
        wednesday: boolean;
        thursday: boolean;
        friday: boolean;
        saturday: boolean;
    };
}

interface StaffBudgetMaintenanceDailogProps {
    open: boolean;
    onClose?: () => void;
    data: StaffBudgetForm;
    isDateRangeDisabled: boolean;
    currency: string;
    staffId: string;
}

interface StaffBudgetMaintenanceDailogState {
    isLoading: boolean;
    onSubmitLoading: boolean;
    isFormChanged: boolean;
    showDiscard: boolean;
}

export const StaffBudgetMaintenanceDailog: React.FC<StaffBudgetMaintenanceDailogProps> = ( props ) => {

    const moment = extendMoment(Moment);

    const classes = useStyles();
    
    // tslint:disable-next-line: no-any
    const required = (label: string) => (value: any) => (value !== undefined && value !== null ? undefined : `${label} is required`);

    // tslint:disable-next-line: no-any
    const mustBeNumber = (value: any) => (isNaN(value) ? 'Must be a number' : undefined);

    // tslint:disable-next-line: no-any
    const composeValidators = (...validators: any[]) => (value: any) => validators.reduce((error, validator) => error || validator(value), undefined);
    
    const [state, setState] = useState<StaffBudgetMaintenanceDailogState>({
        isLoading: true,
        onSubmitLoading: false,
        isFormChanged: false,
        showDiscard: false,
    });

    const [budgetForm, setBudgetForm] = useState<StaffBudgetMaintenanceDailogForm>({
        startDate: props.data.startDate,
        endDate: props.data.endDate,
        billableHoursPerDay: props.data.billableHoursPerDay,
        billableAmountPerDay: props.data.billableAmountPerDay,
        nonBillableHoursPerDay: props.data.nonBillableHoursPerDay,
        feesBilledPerDay: props.data.feesBilledPerDay,
        feesCollectedPerDay: props.data.feesCollectedPerDay,
        week: {
            sunday: false,
            monday: true,
            tuesday: true,
            wednesday: true,
            thursday: true,
            friday: true,
            saturday: false,
        }
    });

    useEffect(() => {
        setBudgetForm((prevBudgetForm) => {
            return {
                ...prevBudgetForm,
                startDate: props.data.startDate,
                endDate: props.data.endDate,
                billableHoursPerDay: props.data.billableHoursPerDay,
                billableAmountPerDay: props.data.billableAmountPerDay,
                nonBillableHoursPerDay: props.data.nonBillableHoursPerDay,
                feesBilledPerDay: props.data.feesBilledPerDay,
                feesCollectedPerDay: props.data.feesCollectedPerDay,
            };
        });
        setState((prevState) => {
            return {
                ...prevState,
                isLoading: false
            };
        });
    },        [props.data]);

    const onSubmit = (form: FormApi<StaffBudgetMaintenanceDailogForm>, values: StaffBudgetMaintenanceDailogForm | AnyObject) => {
        // tslint:disable-next-line: no-console
        console.log('submit', values);

        const monthRange = moment.range(values.startDate, values.endDate);

        const days = Array.from(monthRange.by('day'));

        const staffDailyBudgetsInput: StaffDailyBudgetsInput = { staffDailyBudgetsInput: []};
        
        if (props.isDateRangeDisabled) {
            staffDailyBudgetsInput.staffDailyBudgetsInput.push({
                staffId: props.staffId,
                budgetDate: moment(values.startDate).format('L').toString(),
                chargeableHours: values.billableHoursPerDay,
                nonChargeableHours: values.nonBillableHoursPerDay,
                feesRecorded: values.billableAmountPerDay,
                feesBilled: values.feesBilledPerDay,
                feesCollected: values.feesCollectedPerDay,
            });
        } else {
            // update entire month
            days.forEach((it, index) => {
                if (values.week.sunday && it.weekday() === 0) {
                    staffDailyBudgetsInput.staffDailyBudgetsInput.push({
                        staffId: props.staffId,
                        budgetDate: it.format('L').toString(),
                        chargeableHours: values.billableHoursPerDay,
                        nonChargeableHours: values.nonBillableHoursPerDay,
                        feesRecorded: values.billableAmountPerDay,
                        feesBilled: values.feesBilledPerDay,
                        feesCollected: values.feesCollectedPerDay,
                    });
                } else if (values.week.monday && it.weekday() === 1) {
                    staffDailyBudgetsInput.staffDailyBudgetsInput.push({
                        staffId: props.staffId,
                        budgetDate: it.format('L').toString(),
                        chargeableHours: values.billableHoursPerDay,
                        nonChargeableHours: values.nonBillableHoursPerDay,
                        feesRecorded: values.billableAmountPerDay,
                        feesBilled: values.feesBilledPerDay,
                        feesCollected: values.feesCollectedPerDay,
                    });
                } else if (values.week.tuesday && it.weekday() === 2) {
                    staffDailyBudgetsInput.staffDailyBudgetsInput.push({
                        staffId: props.staffId,
                        budgetDate: it.format('L').toString(),
                        chargeableHours: values.billableHoursPerDay,
                        nonChargeableHours: values.nonBillableHoursPerDay,
                        feesRecorded: values.billableAmountPerDay,
                        feesBilled: values.feesBilledPerDay,
                        feesCollected: values.feesCollectedPerDay,
                    });
                } else if (values.week.wednesday && it.weekday() === 3) {
                    staffDailyBudgetsInput.staffDailyBudgetsInput.push({
                        staffId: props.staffId,
                        budgetDate: it.format('L').toString(),
                        chargeableHours: values.billableHoursPerDay,
                        nonChargeableHours: values.nonBillableHoursPerDay,
                        feesRecorded: values.billableAmountPerDay,
                        feesBilled: values.feesBilledPerDay,
                        feesCollected: values.feesCollectedPerDay,
                    });
                } else if (values.week.thursday && it.weekday() === 4) {
                    staffDailyBudgetsInput.staffDailyBudgetsInput.push({
                        staffId: props.staffId,
                        budgetDate: it.format('L').toString(),
                        chargeableHours: values.billableHoursPerDay,
                        nonChargeableHours: values.nonBillableHoursPerDay,
                        feesRecorded: values.billableAmountPerDay,
                        feesBilled: values.feesBilledPerDay,
                        feesCollected: values.feesCollectedPerDay,
                    });
                } else if (values.week.friday && it.weekday() === 5) {
                    staffDailyBudgetsInput.staffDailyBudgetsInput.push({
                        staffId: props.staffId,
                        budgetDate: it.format('L').toString(),
                        chargeableHours: values.billableHoursPerDay,
                        nonChargeableHours: values.nonBillableHoursPerDay,
                        feesRecorded: values.billableAmountPerDay,
                        feesBilled: values.feesBilledPerDay,
                        feesCollected: values.feesCollectedPerDay,
                    });
                } else if (values.week.saturday && it.weekday() === 6) {
                    staffDailyBudgetsInput.staffDailyBudgetsInput.push({
                        staffId: props.staffId,
                        budgetDate: it.format('L').toString(),
                        chargeableHours: values.billableHoursPerDay,
                        nonChargeableHours: values.nonBillableHoursPerDay,
                        feesRecorded: values.billableAmountPerDay,
                        feesBilled: values.feesBilledPerDay,
                        feesCollected: values.feesCollectedPerDay,
                    });
                }
            });
        }

        // tslint:disable-next-line: no-console
        console.log('staffDailyBudgetsInput', staffDailyBudgetsInput);
        updateStaffDialy(staffDailyBudgetsInput);
        
    };

    const updateStaffDialy = (input: StaffDailyBudgetsInput) => {

        client.mutate({
            mutation: UpdateStaffDailyBudgetMutation,
            variables: {
                UpdateStaffDailyBudgetInput: input
            },
        })
        // tslint:disable-next-line: no-any
        .then((results: { data: any; }) => { 
            if (results.data) {
                if (results.data.error === null || results.data.error === undefined) {
                    showNotification(null, 'Successfully submitted', 'info'); 
                    if (props.onClose) {
                        props.onClose();
                    }
                } else {
                    showNotification('Failed to update staff daily budget.', results.data.error, 'error'); 
                }
            }            
        })
        // tslint:disable-next-line:no-any
        .catch((reason: any) => { showNotification('Failed to update staff daily budget.', reason, 'error'); });   
    };

    // const getInput = (input: StaffDailyBudgetsInput) => {
    //     return {
    //         input           
    //     };
    // };

    // tslint:disable-next-line: no-any
    const onDiscardChanges = (agree: boolean) => {
        if (agree) {
            if (props.onClose) {
                props.onClose();
            }
        } else {
            // showDiscard false
            // form.change('showDiscard', false);
            setState((prevState) => {
                return {
                    ...prevState,
                    showDiscard: false
                };
            });
        }
    };

    // tslint:disable-next-line: no-any
    const onFormValueChanged = (changeProps: any) => {
        if (!changeProps.pristine) {
            setState((prevState) => {
                return {
                    ...prevState,
                    isFormChanged: true
                };
            });
        }
    };

    const onClose = () => {
        if (state.isFormChanged) {
            setState((prevState) => {
                return {
                    ...prevState,
                    showDiscard: true
                };
            });
        } else {
            if (props.onClose) {
                props.onClose();
            }
        }
    };

    const actionButton = (
        // tslint:disable-next-line: no-any
        form: FormApi<StaffBudgetMaintenanceDailogForm>,
        submitting: boolean,
        pristine: boolean,
        // tslint:disable-next-line: no-any
        values: StaffBudgetMaintenanceDailogForm
    ) => {
        return (
            <div className={classes.buttonContainer}>
                {/* <RenderCount /> */}
                <div>
                    <Button
                        color="primary"
                        type="submit"
                        className={classes.button}
                        onClick={() => {
                            // code here..
                        }}
                        disabled={submitting || pristine || state.onSubmitLoading}
                    >
                        Update
                    </Button>
                </div>
                <div>
                    <Button
                        color="primary"
                        type="button"
                        className={classes.button}
                        onClick={onClose}
                    >
                        Cancel
                    </Button>
                </div>
            </div>
        );
    };

    return (
        <Dialog
            open={props.open}
            onClose={props.onClose}
            className={classes.root}
        >
            <DialogBox
                title="Budget"
                // tslint:disable-next-line:max-line-length
                content={`Are you sure you want to close the form?`}
                show={state.showDiscard ? state.showDiscard : false}
                isAgree={onDiscardChanges}
                disAgreeLabel={'No'}
                agreeLabel={'Yes'}
            />
            <DialogTitle >Edit Budget</DialogTitle>
                {state.isLoading ? (
                    <DialogContent dividers={true}>
                        <div className={classes.loaderWrapper}>
                            <RvLoader size="small" label="Loading..."/>
                        </div>
                    </DialogContent>
                ) : (
                    <Form
                        onSubmit={(values, form: FormApi<StaffBudgetMaintenanceDailogForm>) => onSubmit(form, values)}                
                        initialValues={budgetForm}
                        initialValuesEqual={() => true}
                        keepDirtyOnReinitialize={true}
                        subscription={{ submitting: true, pristine: true }}
                        validate={(values) => {

                            let errors = {
                                billableHoursPerDay: '',
                                nonBillableHoursPerDay: ''
                            };

                            let billableHoursPerDay = 0;
                            let nonBillableHoursPerDay = 0;

                            if (typeof values.billableHoursPerDay === 'string') {
                                // tslint:disable-next-line: radix
                                billableHoursPerDay = parseInt(values.billableHoursPerDay);
                            } else {
                                billableHoursPerDay = values.billableHoursPerDay ? values.billableHoursPerDay : 0;
                            }

                            if (typeof values.nonBillableHoursPerDay === 'string') {
                                // tslint:disable-next-line: radix
                                nonBillableHoursPerDay = parseInt(values.nonBillableHoursPerDay);
                            } else {
                                nonBillableHoursPerDay = values.nonBillableHoursPerDay ? values.nonBillableHoursPerDay : 0;
                            }

                            if (billableHoursPerDay + nonBillableHoursPerDay > 24) {
                                errors.billableHoursPerDay = 'Combined total of Billable and Non Billable hours should not exceed 24 hours.';
                                errors.nonBillableHoursPerDay = 'Combined total of Billable and Non Billable hours should not exceed 24 hours.';

                                return errors;

                            } else {
                                return undefined;
                            }
                        }}
                        render={({handleSubmit, form, submitting, pristine, values}) => (
                            <form onSubmit={handleSubmit} id="taskForm">
                                <FormSpy 
                                    subscription={{ pristine: true, values: true }}
                                    // tslint:disable-next-line: no-shadowed-variable
                                    onChange={props => {
                                        onFormValueChanged(props);
                                    }}
                                />
                                <DialogContent dividers={true}>
                                    <Grid container={true} spacing={3}>
                                        <Grid item={true} xs={12} md={6}>
                                            <Field
                                                name="startDate"
                                                validate={required('Start date')}
                                            >
                                                {({ input, meta }) => (
                                                    <KeyboardDatePicker
                                                        {...input}
                                                        className={classes.textField}
                                                        name="startDate"
                                                        label="Start Date"
                                                        format="DD/MM/YYYY"
                                                        placeholder="dd/mm/yyyy"
                                                        animateYearScrolling={true}
                                                        // tslint:disable-next-line: max-line-length
                                                        autoOk={true}
                                                        allowKeyboardControl={true}
                                                        variant={'inline'}
                                                        error={meta.error && meta.touched}
                                                        inputProps={{autoComplete: 'off'}}
                                                        InputLabelProps={{ shrink: true }}
                                                        helperText={
                                                            meta.error &&
                                                            meta.touched
                                                                ? meta.error
                                                                : ''
                                                        }
                                                        disabled={props.isDateRangeDisabled}
                                                    />
                                                )}
                                            </Field>
                                        </Grid>
                                        <Grid item={true} xs={12} md={6}>
                                        <FormSpy subscription={{values: true, validating: true}}>
                                            {/* tslint:disable-next-line: no-shadowed-variable */}
                                                {({ values }) => (
                                                    <Field
                                                        name="endDate"
                                                        validate={required('End date')}
                                                    >
                                                        {({ input, meta }) => (
                                                            <KeyboardDatePicker
                                                                {...input}
                                                                className={classes.textField}
                                                                name="endDate"
                                                                label="End Date"
                                                                format="DD/MM/YYYY"
                                                                placeholder="dd/mm/yyyy"
                                                                animateYearScrolling={true}
                                                                // tslint:disable-next-line: max-line-length
                                                                autoOk={true}
                                                                allowKeyboardControl={true}
                                                                variant={'inline'}
                                                                error={meta.error && meta.touched}
                                                                inputProps={{autoComplete: 'off'}}
                                                                InputLabelProps={{ shrink: true }}
                                                                helperText={
                                                                    meta.error &&
                                                                    meta.touched
                                                                        ? meta.error
                                                                        : ''
                                                                }
                                                                disabled={props.isDateRangeDisabled}
                                                                minDate={values.startDate}
                                                            />
                                                        )}
                                                    </Field>
                                                )}
                                            </FormSpy>
                                        </Grid>
                                        {UserFeature.HasAccess(UserFeatureEnum.hasStaffBudgetProductionView) && 
                                        UserFeature.HasAccess(UserFeatureEnum.hasStaffBudgetProductionEdit) && (
                                            <>
                                                <Grid item={true} xs={12} md={6}>
                                                    <Field
                                                        name="billableHoursPerDay"
                                                        validate={composeValidators(required('Billable hours per day'), mustBeNumber)}
                                                        className={classes.textField}
                                                        label={'Billable hours per day'}
                                                        decimalScale={1}
                                                        component={CurrencyInput2}
                                                    />
                                                </Grid>
                                                <Grid item={true} xs={12} md={6}>
                                                    <Field
                                                        name="nonBillableHoursPerDay"
                                                        validate={composeValidators(required('Non Billable hours per day'), mustBeNumber)}
                                                        className={classes.textField}
                                                        label="Non Billable hours per day"
                                                        decimalScale={1}
                                                        component={CurrencyInput2}
                                                    />
                                                </Grid>
                                                <Grid item={true} xs={12} md={4}>
                                                    <Field
                                                        name="billableAmountPerDay"
                                                        validate={composeValidators(required('Fees recorded per day'), mustBeNumber)}
                                                        className={classes.textField}
                                                        label="Fees recorded per day"
                                                        prefix={props.currency}
                                                        component={CurrencyInput2}
                                                    />
                                                </Grid>
                                            </>
                                        )}
                                        {UserFeature.HasAccess(UserFeatureEnum.hasStaffBudgetBillingsView) && 
                                        UserFeature.HasAccess(UserFeatureEnum.hasStaffBudgetBillingsEdit) && (
                                            <Grid item={true} xs={12} md={4}>
                                                <Field
                                                    name="feesBilledPerDay"
                                                    validate={composeValidators(required('Fees billed per day'), mustBeNumber)}
                                                    className={classes.textField}
                                                    label="Fees billed per day"
                                                    prefix={props.currency}
                                                    component={CurrencyInput2}
                                                />
                                            </Grid>
                                        )}
                                        {UserFeature.HasAccess(UserFeatureEnum.hasStaffBudgetCollectionsView) && 
                                        UserFeature.HasAccess(UserFeatureEnum.hasStaffBudgetCollectionsEdit) && (
                                            <Grid item={true} xs={12} md={4}>
                                                <Field
                                                    name="feesCollectedPerDay"
                                                    validate={composeValidators(required('Fees collected per day'), mustBeNumber)}
                                                    className={classes.textField}
                                                    label="Fees collected per day"
                                                    prefix={props.currency}
                                                    component={CurrencyInput2}
                                                />
                                            </Grid>
                                        )}
                                        {!!!props.isDateRangeDisabled && (
                                            <Grid item={true} xs={12} md={12}>
                                                <FormControl component="fieldset">
                                                    {/* <FormLabel component="legend">Label Placement</FormLabel> */}
                                                    <FormGroup aria-label="position" row={true}>
                                                        <Field
                                                            name="week.sunday"
                                                            subscription={{ touched: true, error: true, value: true }}
                                                        >
                                                            {({ input, meta }) => (
                                                                <FormControlLabel
                                                                    control={
                                                                        <Checkbox
                                                                            {...input}
                                                                            color="primary"
                                                                            // onChange={(event)=> input.onChange(event.target.checked)}
                                                                            checked={input.value}
                                                                        />
                                                                    }
                                                                    labelPlacement="bottom"
                                                                    label="Sun"
                                                                />
                                                            )}
                                                        </Field>
                                                        <Field
                                                            name="week.monday"
                                                            subscription={{ touched: true, error: true, value: true }}
                                                        >
                                                            {({ input, meta }) => (
                                                                <FormControlLabel
                                                                    control={
                                                                        <Checkbox
                                                                            {...input}
                                                                            color="primary"
                                                                            // onChange={(event)=> input.onChange(event.target.checked)}
                                                                            checked={input.value}
                                                                        />
                                                                    }
                                                                    labelPlacement="bottom"
                                                                    label="Mon"
                                                                />
                                                            )}
                                                        </Field>
                                                        <Field
                                                            name="week.tuesday"
                                                            subscription={{ touched: true, error: true, value: true }}
                                                        >
                                                            {({ input, meta }) => (
                                                                <FormControlLabel
                                                                    control={
                                                                        <Checkbox
                                                                            {...input}
                                                                            color="primary"
                                                                            // onChange={(event)=> input.onChange(event.target.checked)}
                                                                            checked={input.value}
                                                                        />
                                                                    }
                                                                    labelPlacement="bottom"
                                                                    label="Tue"
                                                                />
                                                            )}
                                                        </Field>
                                                        <Field
                                                            name="week.wednesday"
                                                            subscription={{ touched: true, error: true, value: true }}
                                                        >
                                                            {({ input, meta }) => (
                                                                <FormControlLabel
                                                                    control={
                                                                        <Checkbox
                                                                            {...input}
                                                                            color="primary"
                                                                            // onChange={(event)=> input.onChange(event.target.checked)}
                                                                            checked={input.value}
                                                                        />
                                                                    }
                                                                    labelPlacement="bottom"
                                                                    label="Wed"
                                                                />
                                                            )}
                                                        </Field>
                                                        <Field
                                                            name="week.thursday"
                                                            subscription={{ touched: true, error: true, value: true }}
                                                        >
                                                            {({ input, meta }) => (
                                                                <FormControlLabel
                                                                    control={
                                                                        <Checkbox
                                                                            {...input}
                                                                            color="primary"
                                                                            // onChange={(event)=> input.onChange(event.target.checked)}
                                                                            checked={input.value}
                                                                        />
                                                                    }
                                                                    labelPlacement="bottom"
                                                                    label="Thu"
                                                                />
                                                            )}
                                                        </Field>
                                                        <Field
                                                            name="week.friday"
                                                            subscription={{ touched: true, error: true, value: true }}
                                                        >
                                                            {({ input, meta }) => (
                                                                <FormControlLabel
                                                                    control={
                                                                        <Checkbox
                                                                            {...input}
                                                                            color="primary"
                                                                            // onChange={(event)=> input.onChange(event.target.checked)}
                                                                            checked={input.value}
                                                                        />
                                                                    }
                                                                    labelPlacement="bottom"
                                                                    label="Fri"
                                                                />
                                                            )}
                                                        </Field>
                                                        <Field
                                                            name="week.saturday"
                                                            subscription={{ touched: true, error: true, value: true }}
                                                        >
                                                            {({ input, meta }) => (
                                                                <FormControlLabel
                                                                    control={
                                                                        <Checkbox
                                                                            {...input}
                                                                            color="primary"
                                                                            // onChange={(event)=> input.onChange(event.target.checked)}
                                                                            checked={input.value}
                                                                        />
                                                                    }
                                                                    labelPlacement="bottom"
                                                                    label="Sat"
                                                                />
                                                            )}
                                                        </Field>
                                                    </FormGroup>
                                                </FormControl>
                                            </Grid>
                                        )}
                                    </Grid>
                                    {/* {printJson(values)} */}
                                </DialogContent>
                                <DialogActions>
                                    {actionButton(form, submitting, pristine, values)}
                                </DialogActions>
                            </form>
                        )} 
                    />
                )}
        </Dialog>
    );
};

// tslint:disable-next-line: no-any
export function printJson(values: any) {
    if (values) {
        return (
            <>
                <pre>
                    {JSON.stringify(values, undefined, 2)}
                </pre>
            </>
        );
    } else {
        return (
            <>
                <FormSpy subscription={{ values: true }}>
                    {/* tslint:disable-next-line: no-shadowed-variable */}
                    {({ values }) => (
                        <pre>
                            {JSON.stringify(values, undefined, 2)}
                            {/* <RenderCount /> */}
                        </pre>
                    )}
                </FormSpy>
            </>
        );
    }
}

const UpdateStaffDailyBudgetMutation = gql`
    mutation UpdateStaffDailyBudget($UpdateStaffDailyBudgetInput : UpdateStaffDailyBudgetInput) {
        updateStaffDailyBudget(input: $UpdateStaffDailyBudgetInput)
    }
`;